/* eslint-disable import/prefer-default-export */
import * as HttpStatus from 'http-status-codes';
import axios from 'axios';
import * as t from './actionTypes';

const API_BASE = process.env.REACT_APP_API_BASE;
const RISK_COACH_API = process.env.REACT_APP_RISK_COACH_API;
const RISK_COACH_API_KEY = process.env.REACT_APP_RISK_COACH_API_KEY;
const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';
const PAGE_SIZE = 100;
const TAXONOMY_CODE = 4;

const requestSurvey = () => ({ type: t.REQUEST_SURVEY });
const receiveSurvey = (data) => ({ type: t.RECEIVE_SURVEY, data });
const requestReport = () => ({ type: t.REQUEST_REPORT });
const receiveReport = (data) => ({ type: t.RECEIVE_REPORT, data });
const requestSection = (notLoading) => ({
  type: t.REQUEST_SECTION,
  notLoading,
});
const receiveSection = (data) => ({
  type: t.RECEIVE_SECTION,
  data,
});
const requestMediaLibrary = () => ({ type: t.REQUEST_MEDIA_LIBRARY });
const receiveMediaLibrary = (data) => ({
  type: t.RECEIVE_MEDIA_LIBRARY,
  mediaLibrary: data,
});
const requestFilesInSection = () => ({
  type: t.REQUEST_FETCH_FILES_IN_SECTION,
});
const receiveFilesInSection = (data) => ({
  type: t.RECEIVE_FETCH_FILES_IN_SECTION,
  filesInSection: data,
});

const requestFilesInQuestion = () => ({
  type: t.REQUEST_FETCH_FILES_IN_QUESTION,
});
const receiveFetchFilesInQuestion = (data) => ({
  type: t.RECEIVE_FETCH_FILES_IN_QUESTION,
  filesInQuestion: data,
});

const requestUploadFileToSurvey = () => ({
  type: t.REQUEST_UPLOAD_FILE_TO_SURVEY,
});
const receiveUploadFileToSurvey = (data) => ({
  type: t.RECEIVE_UPLOAD_FILE_TO_SURVEY,
  file: data,
});
const requestUploadFileToQuestion = () => ({
  type: t.REQUEST_UPLOAD_FILE_TO_QUESTION,
});
const receiveUploadFileToQuestion = (data) => ({
  type: t.RECEIVE_UPLOAD_FILE_TO_QUESTION,
  file: data,
});
const requestAttachFileToQuestion = () => ({
  type: t.REQUEST_ATTACH_FILE_TO_QUESTION,
});
const receiveAttachFileToQuestion = (questionId, file) => ({
  type: t.RECEIVE_ATTACH_FILE_TO_QUESTION,
  questionId,
  file,
});
const requestDetachFileFromQuestion = () => ({
  type: t.REQUEST_DETACH_FILE_FROM_QUESTION,
});
const receiveDetachFileFromQuestion = (questionId, fileId) => ({
  type: t.RECEIVE_DETACH_FILE_FROM_QUESTION,
  questionId,
  fileId,
});
const requestDeleteFile = () => ({ type: t.REQUEST_DELETE_FILE });
const receiveDeleteFile = (data) => ({
  type: t.RECEIVE_DELETE_FILE,
  mediaLibrary: data,
});
const requestEditFile = () => ({ type: t.REQUEST_EDIT_FILE });
const receiveEditFile = () => ({ type: t.RECEIVE_EDIT_FILE });
const requestUpdateFile = () => ({ type: t.REQUEST_UPDATE_FILE });
const receiveUpdateFile = (data) => ({
  type: t.RECEIVE_UPDATE_FILE,
  mediaLibrary: data,
});
const requestSaveAnswers = () => ({ type: t.REQUEST_SAVE_ANSWERS });
const receiveSaveAnswers = () => ({ type: t.RECEIVE_SAVE_ANSWERS });
const requestTemplate = () => ({ type: t.REQUEST_TEMPLATE });
const receiveTemplate = (data) => ({
  type: t.RECEIVE_TEMPLATE,
  data,
});
const requestAddSection = () => ({ type: t.REQUEST_ADD_SECTION });
const receiveAddSection = () => ({ type: t.RECEIVE_ADD_SECTION });
const requestEditSection = () => ({ type: t.REQUEST_EDIT_SECTION });
const receiveEditSection = (id, sectionId, data) => ({
  type: t.RECEIVE_EDIT_SECTION,
  surveyId: id,
  sectionId,
  data,
});

const requestIndustries = () => ({ type: t.REQUEST_INDUSTRIES });
const receiveIndustries = () => ({
  type: t.RECEIVE_INDUSTRIES,
});

const requestPhotographicSchedule = () => ({
  type: t.REQUEST_PHOTOGRAPHIC_SCHEDULE,
});
const receivePhotographicSchedule = (data) => ({
  type: t.RECEIVE_PHOTOGRAPHIC_SCHEDULE,
  data,
});

export const fetchSurvey = (id) => async (dispatch) => {
  dispatch(requestSurvey());
  const url = `${API_BASE}surveys/${id}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveSurvey(res.data));
    return Promise.resolve(res);
  } catch (response) {
    dispatch(receiveSurvey());
    const status = response ? response.status : null;
    switch (status) {
      case HttpStatus.BAD_REQUEST:
        return Promise.reject(MSG_ERROR);
      default:
        return Promise.reject(MSG_ERROR);
    }
  }
};

export const fetchReport = (id) => async (dispatch) => {
  dispatch(requestReport());
  const url = `${API_BASE}surveys/${id}/export`;
  try {
    const res = await axios.get(url);
    dispatch(receiveReport(res.data));
    return Promise.resolve(res);
  } catch (response) {
    dispatch(receiveReport());
    const status = response ? response.status : null;
    switch (status) {
      case HttpStatus.BAD_REQUEST:
        return Promise.reject(MSG_ERROR);
      default:
        return Promise.reject(MSG_ERROR);
    }
  }
};

export const fetchSection = (id, sectionId, notLoading) => async (dispatch) => {
  dispatch(requestSection(notLoading));
  const url = `${API_BASE}surveys/${id}/sections/${sectionId}`;
  try {
    const res = await axios.get(url);
    dispatch(receiveSection(res.data));
    return Promise.resolve(res);
  } catch (response) {
    dispatch(receiveSection());
    const status = response ? response.status : null;
    switch (status) {
      case HttpStatus.BAD_REQUEST:
        return Promise.reject(MSG_ERROR);
      default:
        return Promise.reject(MSG_ERROR);
    }
  }
};

// fetch all files in a survey.
export const fetchMediaLibrary = (id, params) => async (dispatch) => {
  dispatch(requestMediaLibrary());
  const url = `${API_BASE}surveys/${id}/files`;
  try {
    const res = await axios.get(url, {
      withCredentials: true,
      params,
    });
    const mediaLibrary = { headers: res.headers, data: res.data };
    await dispatch(receiveMediaLibrary(mediaLibrary));
    return Promise.resolve('Media Library has been successfully fetched');
  } catch (err) {
    dispatch(receiveMediaLibrary());
    return Promise.reject(new Error('Media Library is not fetched'));
  }
};

export const fetchFilesInSection =
  (id, sectionId, params) => async (dispatch) => {
    dispatch(requestFilesInSection());
    const url = `${API_BASE}surveys/${id}/sections/${sectionId}/files`;
    try {
      const res = await axios.get(url, {
        withCredentials: true,
        params,
      });
      const filesInSection = { headers: res.headers, data: res.data };
      await dispatch(receiveFilesInSection(filesInSection));
      return Promise.resolve(
        'Files in the section have been successfully fetched',
      );
    } catch (err) {
      dispatch(receiveFilesInSection());
      return Promise.reject(new Error('Files in the section are not fetched'));
    }
  };

export const fetchFilesInQuestion =
  (id, questionId, params) => async (dispatch) => {
    dispatch(requestFilesInQuestion());
    const url = `${API_BASE}surveys/${id}/questions/${questionId}/files`;
    try {
      const res = await axios.get(url, {
        withCredentials: true,
        params,
      });
      const filesInQuestion = { headers: res.headers, data: res.data };
      await dispatch(receiveFetchFilesInQuestion(filesInQuestion));
      return Promise.resolve(
        'Files in the question have been successfully fetched',
      );
    } catch (err) {
      dispatch(receiveFetchFilesInQuestion());
      return Promise.reject(new Error('Files in the question are not fetched'));
    }
  };

export const uploadFileToSurvey = (id, data) => async (dispatch) => {
  dispatch(requestUploadFileToSurvey());
  const url = `${API_BASE}surveys/${id}/files`;
  try {
    await axios.post(url, data);
    dispatch(receiveUploadFileToSurvey());
    return Promise.resolve('File has been successfully uploaded');
  } catch (err) {
    dispatch(receiveUploadFileToSurvey());
    return Promise.reject(new Error('File is not uploaded'));
  }
};

export const uploadFileToQuestion =
  (id, questionId, data) => async (dispatch) => {
    dispatch(requestUploadFileToQuestion());
    const url = `${API_BASE}surveys/${id}/questions/${questionId}/files`;
    try {
      await axios.post(url, data);
      dispatch(receiveUploadFileToQuestion());
      return Promise.resolve(
        'File has been successfully uploaded and attached to the question',
      );
    } catch (err) {
      dispatch(receiveUploadFileToQuestion());
      return Promise.reject(new Error('File is not uploaded'));
    }
  };

export const attachFileToQuestion =
  (id, questionId, data, file) => async (dispatch) => {
    dispatch(requestAttachFileToQuestion());
    const url = `${API_BASE}surveys/${id}/questions/${questionId}/files`;
    try {
      await axios.put(url, data);
      dispatch(receiveAttachFileToQuestion(questionId, file));
      return Promise.resolve(
        'File has been successfully attached to the question',
      );
    } catch (err) {
      dispatch(receiveAttachFileToQuestion());
      return Promise.reject(new Error('File is not attached to the question'));
    }
  };

export const detachFileFromQuestion =
  (id, questionId, fileId) => async (dispatch) => {
    dispatch(requestDetachFileFromQuestion());
    const url = `${API_BASE}surveys/${id}/questions/${questionId}/files/${fileId}`;
    try {
      await axios.delete(url);
      dispatch(receiveDetachFileFromQuestion(questionId, fileId));
      return Promise.resolve(
        'File has been successfully detached from the question',
      );
    } catch (err) {
      dispatch(receiveDetachFileFromQuestion());
      return Promise.reject(
        new Error('File is not detached from the question'),
      );
    }
  };

export const deleteFile = (id, fileId) => async (dispatch) => {
  dispatch(requestDeleteFile());
  const url = `${API_BASE}surveys/${id}/files/${fileId}`;
  try {
    await axios.delete(url);
    dispatch(receiveDeleteFile());
    return Promise.resolve('File has been successfully deleted');
  } catch (err) {
    dispatch(receiveDeleteFile());
    return Promise.reject(new Error('File is not deleted'));
  }
};

export const editFile = (id, fileId, data) => async (dispatch) => {
  dispatch(requestEditFile());
  const url = `${API_BASE}surveys/${id}/files/${fileId}`;
  try {
    await axios.patch(url, data);
    dispatch(receiveEditFile());
    return Promise.resolve('File has been successfully edited');
  } catch (err) {
    dispatch(receiveEditFile());
    return Promise.reject(new Error('File is not edited'));
  }
};

export const updateFile = (id, data) => async (dispatch) => {
  dispatch(requestUpdateFile());
  const url = `${API_BASE}surveys/${id}/files`;
  try {
    await axios.put(url, data);
    dispatch(receiveUpdateFile());
    return Promise.resolve('File has been successfully updated');
  } catch (err) {
    dispatch(receiveUpdateFile());
    return Promise.reject(new Error('File is not updated'));
  }
};

export const saveAnswers = (id, data) => async (dispatch) => {
  dispatch(requestSaveAnswers());
  const url = `${API_BASE}surveys/${id}/questions`;
  try {
    const res = await axios.patch(url, data);
    dispatch(receiveSaveAnswers());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveSaveAnswers());
    return Promise.reject(err);
  }
};

export const fetchTemplate = () => async (dispatch) => {
  dispatch(requestTemplate());
  const url = `${API_BASE}templates`;
  try {
    const res = await axios.get(url);
    dispatch(receiveTemplate(res.data[0]));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveTemplate());
    return Promise.reject(err);
  }
};

export const addSection = (id, data) => async (dispatch) => {
  dispatch(requestAddSection());
  const url = `${API_BASE}surveys/${id}/sections`;
  try {
    const res = await axios.post(url, data);
    dispatch(receiveAddSection());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(receiveAddSection());
    return Promise.reject(err);
  }
};

export const editSection = (id, sectionId, data) => async (dispatch) => {
  dispatch(requestEditSection());
  const url = `${API_BASE}surveys/${id}/sections/${sectionId}`;
  try {
    await axios.patch(url, data);
    dispatch(receiveEditSection(id, sectionId, data));
    return Promise.resolve('Section has been successfully edited');
  } catch (err) {
    dispatch(receiveEditSection());
    return Promise.reject(new Error('Section is not edited'));
  }
};

export const fetchIndustries = (params, nextUrl) => async (dispatch) => {
  dispatch(requestIndustries());
  const url = nextUrl || `${RISK_COACH_API}industries`;
  try {
    const instance = axios.create({
      headers: {
        'x-api-key': RISK_COACH_API_KEY,
        Accept: 'application/json;v=2.0',
      },
    });
    const res = await instance.get(url, {
      params: { page_size: PAGE_SIZE, taxonomy: TAXONOMY_CODE, ...params },
    });
    dispatch(receiveIndustries());
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchPhotographicSchedule = (id) => async (dispatch) => {
  dispatch(requestPhotographicSchedule());
  const url = `${API_BASE}surveys/${id}/files?attached=true`;
  try {
    const res = await axios.get(url);
    dispatch(receivePhotographicSchedule(res.data));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setPagination = (data) => (dispatch) =>
  dispatch({ type: t.SET_PAGINATION, data });
