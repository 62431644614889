import React from 'react';
import { Form, Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import './style.scss';

export default ({ preId, nextId, form, isDirty, setPristine }) => {
  const history = useHistory();
  const { surveyId } = useParams();

  const handleClick = async (sectionId) => {
    if (isDirty) {
      await form.submit();
      setPristine();
    }
    if (sectionId) {
      history.push(`/surveys/${surveyId}/sections/${sectionId}`);
    }
  };

  return (
    <div className="nav-btn">
      <Form.Item>
        {preId ? (
          <Button
            type="primary"
            htmlType="button"
            className="sc-btn-primary"
            onClick={() => {
              return handleClick(preId);
            }}
          >
            <ArrowLeftOutlined />
          </Button>
        ) : (
          <Button
            type="primary"
            htmlType="button"
            className="sc-btn-secondary disabled"
            onClick={() => {
              return handleClick();
            }}
          >
            <ArrowLeftOutlined />
          </Button>
        )}
      </Form.Item>
      <Form.Item>
        {nextId ? (
          <Button
            type="primary"
            htmlType="button"
            className="sc-btn-primary"
            onClick={() => {
              return handleClick(nextId);
            }}
          >
            <ArrowRightOutlined />
          </Button>
        ) : (
          <Button
            type="primary"
            htmlType="button"
            className="sc-btn-primary disabled"
            onClick={() => {
              return handleClick();
            }}
          >
            <ArrowRightOutlined />
          </Button>
        )}
      </Form.Item>
    </div>
  );
};
