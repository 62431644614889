import { message } from 'antd';
import axios from 'axios';
import * as HttpStatus from 'http-status-codes';

export const SESSION_KEY = 'survey_coach_token';
const SESSION_USERNAME = 'survey_coach_username';
const SESSION_EXPIRY = 'survey_coach_expiry';

const setAuthorizationHeader = () => {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    SESSION_KEY,
  )}`;
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      const status = err && err.response ? err.response.status : null;
      switch (status) {
        case HttpStatus.UNAUTHORIZED:
          message.destroy();
          message.error('Session expired.');
          return Promise.reject(err);
        default:
          return Promise.reject(err);
      }
    },
  );
};

export default {
  get session() {
    return {
      token: localStorage.getItem(SESSION_KEY),
      username: localStorage.getItem(SESSION_USERNAME),
      expiry: localStorage.getItem(SESSION_EXPIRY),
    };
  },
  get isLoggedIn() {
    const expiry = localStorage.getItem(SESSION_EXPIRY);
    if (!expiry || expiry <= Date.now()) return false;
    return !!localStorage.getItem(SESSION_KEY);
  },
  login(token, username, expiry) {
    if (!token) throw new Error('Invalid token');
    localStorage.setItem(SESSION_KEY, token);
    localStorage.setItem(SESSION_USERNAME, username);
    localStorage.setItem(SESSION_EXPIRY, Date.now() + expiry * 1000);
    setAuthorizationHeader();
  },
  logout() {
    localStorage.removeItem(SESSION_KEY);
    localStorage.removeItem(SESSION_USERNAME);
    localStorage.removeItem(SESSION_EXPIRY);
  },
  setAuthorizationHeader,
};
