import React from 'react';
import Layout from '../../components/layout';
import './style.scss';

export default () => {
  return (
    <Layout>
      <div className="home-banner">
        <h1>
          <span>Survey Report</span>
          <span>for every type of industries</span>
        </h1>
        <img src="/images/banner.png" alt="banner" />
      </div>
      <div className="home-content">
        <article className="home-intro">
          <h1>Create professional surveys tailored to your needs</h1>
          <p>
            SurveyCoach offers a tremendous set of tools for designing your
            underwriting survey report, sharing your survey online, and
            previewing your survey&apos;s report. We&apos;ve got a fantastic
            user experience and tons of great features.
          </p>
        </article>
      </div>
    </Layout>
  );
};
