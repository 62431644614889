import React from 'react';
import MediaLibraryForm from './form';
import Modal from '../../../../../../components/modal';

import './style.scss';

const MediaLibraryModal = ({ visible, onOk, onCancel, filter, question }) => {
  return (
    <Modal
      className="media-library-modal"
      width="80vw"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      style={{ maxWidth: 1024 }}
    >
      <MediaLibraryForm
        question={question}
        filter={filter}
        hide={onCancel}
        submitText="IMPORT INTO REPORT"
        cancelText="CANCEL"
        title="Media Library"
      />
    </Modal>
  );
};

export default MediaLibraryModal;
