import React from 'react';
import PropTypes from 'prop-types';
import { Layout as AntLayout, BackTop } from 'antd';
import Header from '../header';
import Footer from '../footer';
import LoginModal from '../login/LoginModal';
import './style.scss';

const { Content } = AntLayout;

const Layout = ({ children, fixed }) => {
  return (
    <AntLayout className="layout">
      <Header fixed={fixed} />
      <Content>{children}</Content>
      <BackTop />
      <Footer />
      <LoginModal />
    </AntLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
