import React from 'react';
import { connect } from 'react-redux';
import Date from '../../../../components/date';
import coverBg from '../../assets/cover_bg.jpg';
import lmiLogo from '../../assets/lmi_logo.png';
import './style.scss';

const Cover = ({ title, date }) => {
  return (
    <>
      <section
        className="report-preview-cover"
        style={{ backgroundImage: `url(${coverBg})` }}
      >
        <div className="title-container">
          <div className="cover-title">
            <h2 className="title-top">{title.toUpperCase()}</h2>
            <h2 className="title-bottom">RISK SURVEY REPORT</h2>
          </div>
          <Date
            date={date}
            style={{
              textAlign: 'end',
              display: 'inherit',
              width: '370px',
              letterSpacing: '0.5px',
            }}
          />
        </div>
        <div className="logo-container">
          <img src={lmiLogo} alt="logo" />
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({ survey }) => ({
  title: survey.title,
  date: survey.created_on,
});

export default connect(mapStateToProps, null)(Cover);
