/* eslint-disable radix */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useParams,
  useLocation,
  useHistory,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
import {
  Upload,
  message,
  Layout as AntLayout,
  Menu,
  Affix,
  Badge,
  Pagination,
} from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  SnippetsOutlined,
  FileImageOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import SurveyLayout from '../../components/layout';
import ErrorBoundary from '../../../../components/error';
import FileList from './components/fileList';
import {
  fetchMediaLibrary,
  uploadFileToSurvey,
  fetchPhotographicSchedule,
  setPagination,
} from '../../actions';
import defaultFile from '../../assets/file.png';
import { DOCUMENT_TYPES, IMAGE_TYPES } from '../../../../shared/constant';
import './style.scss';

const { Content, Sider } = AntLayout;

const ACCEPTED_FILE_TYPE = { all: '', images: 'image', documents: 'document' };

const MediaLibrary = ({
  totalCount,
  counts,
  fetchMediaLibrary,
  uploadFileToSurvey,
  setPagination,
}) => {
  const PAGE_SIZE = 30;

  const [loading, setLoading] = useState(false);
  const [fetchFileType, setFetchFileType] = useState('');
  const location = useLocation();
  const urlPath = location.pathname.split('/');
  const fileType = ACCEPTED_FILE_TYPE[urlPath[urlPath.length - 1]];
  const history = useHistory();
  const { surveyId } = useParams();
  const basePath = `/surveys/${surveyId}/mediaLibrary`;
  const query = new URLSearchParams(useLocation().search);
  const page = Number.parseInt(query.get('page'));
  const [currentPage, setCurrentPage] = useState();
  const [affixTop, setAffixTop] = useState();

  const param = {
    page: currentPage,
    pageSize: PAGE_SIZE,
    type: fetchFileType,
  };

  window.addEventListener(
    'error',
    (e) => {
      if (e.target.tagName.toUpperCase() === 'IMG') {
        e.target.src = defaultFile;
      }
    },
    true,
  );

  useEffect(() => {
    setPagination(param);
  }, [currentPage, fetchFileType]);

  useEffect(() => {
    if (ACCEPTED_FILE_TYPE[fileType]) {
      setFetchFileType(ACCEPTED_FILE_TYPE[fileType]);
    } else {
      setFetchFileType(ACCEPTED_FILE_TYPE.all);
    }

    const handleResize = () => {
      if (window.innerWidth > 768) setAffixTop(234);
      else setAffixTop(100);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    if (fileType !== fetchFileType)
      if (fileType === 'all') setFetchFileType(ACCEPTED_FILE_TYPE.all);
      else setFetchFileType(fileType);
  }, []);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [fetchFileType]);

  useEffect(() => {
    const urlPath = location.pathname.split('/');
    const lastPath = urlPath[urlPath.length - 1];

    if (lastPath === 'mediaLibrary') {
      setCurrentPage(1);
      setFetchFileType('');
    }

    window.scroll(0, 0);
  }, [location.pathname]);

  const getPageCount = (type) => {
    const { image, document } = counts;

    if (type === ACCEPTED_FILE_TYPE.images) return image;
    if (type === ACCEPTED_FILE_TYPE.documents) return document;
    return totalCount;
  };

  const beforeUpload = (file) => {
    const isAcceptedFiletypes =
      IMAGE_TYPES.indexOf(file.type) > -1 ||
      DOCUMENT_TYPES.indexOf(file.type) > -1;
    if (!isAcceptedFiletypes) {
      message.error('The file type is not accepted.');
    }
    const TEN_M = 10 * 1024 * 1024;
    const isLt10M = file.size < TEN_M;
    if (!isLt10M) {
      message.error('Maximum upload file size: 10 MB.');
    }
    return isAcceptedFiletypes && isLt10M;
  };

  const handleUploadChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
    }
  };

  const uploadMedia = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await uploadFileToSurvey(surveyId, formData);
      onSuccess(null, file);
      message.success(res);
      fetchMediaLibrary(surveyId, param);
    } catch (err) {
      onError();
      message.error(err.message);
    }
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case 'all':
        setFetchFileType(ACCEPTED_FILE_TYPE.all);
        break;
      case 'image':
        setFetchFileType(ACCEPTED_FILE_TYPE.images);
        break;
      case 'document':
        setFetchFileType(ACCEPTED_FILE_TYPE.documents);
        break;
      default:
        setFetchFileType(ACCEPTED_FILE_TYPE.all);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handlePageChange = (page) => {
    let urlFileType;

    if (fetchFileType === ACCEPTED_FILE_TYPE.images) {
      urlFileType = 'images';
    } else if (fetchFileType === ACCEPTED_FILE_TYPE.documents) {
      urlFileType = 'documents';
    } else {
      urlFileType = 'all';
    }

    setCurrentPage(page);
    history.push(`${basePath}/${urlFileType}?page=${page}`);
  };

  return (
    <SurveyLayout fixed>
      <ErrorBoundary>
        <AntLayout className="media-library">
          <Sider width={220}>
            <Affix offsetTop={affixTop}>
              <h1 className="media-library-title">MEDIA LIBRARY</h1>
              <Menu
                mode="inline"
                defaultSelectedKeys={!fileType ? 'all' : fileType}
                selectedKeys={fetchFileType === '' ? 'all' : fetchFileType}
                onClick={handleMenuClick}
              >
                <Menu.Item key="all">
                  <Link to={`${basePath}/all?page=${1}`}>
                    <SnippetsOutlined />
                    <span className="media-library-menu">
                      ALL FILES
                      {totalCount > 0 && (
                        <Badge count={totalCount} className="menu-badge" />
                      )}
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="image">
                  <Link to={`${basePath}/images?page=${1}`}>
                    <FileImageOutlined />
                    <span className="media-library-menu">
                      ALL IMAGES
                      {getPageCount('image') > 0 && (
                        <Badge
                          count={getPageCount('image')}
                          className="menu-badge"
                        />
                      )}
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="document">
                  <Link to={`${basePath}/documents?page=${1}`}>
                    <FileTextOutlined />
                    <span className="media-library-menu">
                      ALL DOCUMENTS
                      {getPageCount('document') > 0 && (
                        <Badge
                          count={getPageCount('document')}
                          className="menu-badge"
                        />
                      )}
                    </span>
                  </Link>
                </Menu.Item>
              </Menu>
            </Affix>
          </Sider>
          <Content className="media-library-content">
            <div className="media-library-note">
              <div className="media-library-note-title">
                <span className="is-highlighted">Note:</span>
              </div>
              <div className="media-library-note-desc">
                Accepted filetypes: Images:{' '}
                <span className="is-highlighted">jpg, jpeg, png, gif; </span>
                Documents:{' '}
                <span className="is-highlighted">
                  txt, pdf, doc, docx, xls, xlsx.{' '}
                </span>
                Maximum upload file size:{' '}
                <span className="is-highlighted">10 MB.</span>
              </div>
            </div>
            <Upload
              name="media-library"
              listType="picture-card"
              className="uploader"
              multiple
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleUploadChange}
              customRequest={uploadMedia}
            >
              {uploadButton}
            </Upload>
            <div className="media-library-file-container">
              <Switch>
                <Route
                  exact
                  key="all"
                  path="/surveys/:surveyId/mediaLibrary"
                  component={FileList}
                />
                <Route
                  exact
                  key="subType"
                  path="/surveys/:surveyId/mediaLibrary/:fileType"
                  component={FileList}
                />
              </Switch>
            </div>
            {totalCount && (
              <Pagination
                current={page || currentPage}
                onChange={handlePageChange}
                total={getPageCount(fetchFileType)}
                pageSize={PAGE_SIZE}
                hideOnSinglePage
                showSizeChanger={false}
              />
            )}
          </Content>
        </AntLayout>
      </ErrorBoundary>
    </SurveyLayout>
  );
};

const mapStateToProps = ({ survey }) => ({
  counts: survey.mediaLibrary.data.counts,
  totalCount: survey.mediaLibrary.data.total,
});

const mapDispatchToProps = {
  fetchMediaLibrary,
  uploadFileToSurvey,
  fetchPhotographicSchedule,
  setPagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibrary);
