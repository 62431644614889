/* eslint-disable no-shadow */
/* eslint import/prefer-default-export: off */

import * as HttpStatus from 'http-status-codes';
import axios from 'axios';
import * as t from './actionTypes';

const API_BASE = process.env.REACT_APP_API_BASE;
const MSG_ERROR = 'Sorry, an error occurred. Please try again later.';

const requestSurveyList = () => ({
  type: t.REQUEST_SURVEY_LIST,
});

const receiveSurveyList = (data) => ({
  type: t.RECEIVE_SURVEY_LIST,
  data,
});

const requestDeleteSurvey = () => ({
  type: t.REQUEST_DELETE_SURVEY,
});

const receiveDeleteSurvey = () => ({
  type: t.RECEIVE_DELETE_SURVEY,
});

export const fetchSurveyList = (params) => async (dispatch) => {
  dispatch(requestSurveyList());
  const url = `${API_BASE}surveys`;
  try {
    const res = await axios.get(url, {
      params,
    });
    const { data, headers } = res;
    const results = {
      data,
      total: Number(headers['total-count']) || data.length,
    };
    dispatch(receiveSurveyList(results));
    return Promise.resolve(res);
  } catch ({ response }) {
    dispatch(receiveSurveyList());
    const status = response ? response.status : null;
    switch (status) {
      case HttpStatus.BAD_REQUEST:
        return Promise.reject(MSG_ERROR);
      default:
        return Promise.reject(MSG_ERROR);
    }
  }
};

export const deleteSurvey = (id) => async (dispatch) => {
  dispatch(requestDeleteSurvey());
  const url = `${API_BASE}surveys/${id}`;
  try {
    const res = await axios.delete(url);
    dispatch(receiveDeleteSurvey());
    return Promise.resolve(res);
  } catch (response) {
    dispatch(receiveDeleteSurvey());
    const status = response ? response.status : null;
    switch (status) {
      case HttpStatus.BAD_REQUEST:
        return Promise.reject(MSG_ERROR);
      default:
        return Promise.reject(MSG_ERROR);
    }
  }
};
