import React from 'react';
import moment from 'moment';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Date = ({ date, style }) => {
  const formatDate = moment(date).format('DD/MM/YYYY');
  const dateList = formatDate.split('/');
  return (
    <em style={style}>
      {dateList[0]} 
      {' '}
      {monthNames[dateList[1] - 1]} 
      {' '}
      {dateList[2]}
    </em>
  );
};

export default Date;
