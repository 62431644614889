import _ from 'lodash';
import * as t from './actionTypes';

const initialState = {
  isLoading: false,
  isReportLoading: false,
  isSectionLoading: false,
  isMediaLibraryLoading: false,
  isTemplateLoading: false,
  isFilesInSectionLoading: false,
  isFilesInQuestionLoading: false,
  isAnswerSaving: false,
  isFileUploading: false,
  isFileAttaching: false,
  isFileDetaching: false,
  isFileDeleting: false,
  isFileEditing: false,
  isFileUpdating: false,
  isSectionAdding: false,
  isSectionEditing: false,
  isIndustriesLoading: false,
  isPhotographicScheduleLoading: false,
  pagination: {},
  mediaLibrary: {
    data: {
      files: [],
      totalCount: 0,
      counts: {
        image: 0,
        document: 0,
      },
    },
  },
};

const parseGroups = (data, actionToQuestions) => {
  if (data.length > 0) {
    data.forEach((group) => {
      actionToQuestions(group.questions);
    });
  }
};

const parseSection = (data, actionToQuestions) => {
  if (data) {
    parseGroups(data.groups, actionToQuestions);
    actionToQuestions(data.questions);
    if (data.sections.length > 0) {
      data.sections.forEach((sec) => {
        parseSection(sec, actionToQuestions);
      });
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.REQUEST_SURVEY:
      return { ...state, isLoading: true };
    case t.RECEIVE_SURVEY:
      return { ...state, isLoading: false, ...action.data };
    case t.REQUEST_REPORT:
      return { ...state, isReportLoading: true };
    case t.RECEIVE_REPORT:
      return {
        ...state,
        isReportLoading: false,
        report: action.data || state.report,
      };
    case t.REQUEST_SECTION:
      return {
        ...state,
        isSectionLoading: !action.notLoading,
      };
    case t.RECEIVE_SECTION:
      return {
        ...state,
        isSectionLoading: false,
        section: action.data || state.section,
      };
    case t.REQUEST_MEDIA_LIBRARY:
      return { ...state, isMediaLibraryLoading: true };
    case t.RECEIVE_MEDIA_LIBRARY:
      return {
        ...state,
        isMediaLibraryLoading: false,
        mediaLibrary: action.mediaLibrary || state.mediaLibrary,
      };
    case t.REQUEST_FETCH_FILES_IN_SECTION:
      return { ...state, isFilesInSectionLoading: true };
    case t.RECEIVE_FETCH_FILES_IN_SECTION:
      return {
        ...state,
        isFilesInSectionLoading: false,
        filesInSection: action.filesInSection || state.filesInSection,
      };
    case t.REQUEST_FETCH_FILES_IN_QUESTION:
      return { ...state, isLoading: true };
    case t.RECEIVE_FETCH_FILES_IN_QUESTION:
      return {
        ...state,
        isLoading: false,
        filesInQuestion: action.filesInQuestion || state.filesInQuestion,
      };
    case t.REQUEST_UPLOAD_FILE_TO_SURVEY:
      return { ...state, isFileUploading: true };
    case t.RECEIVE_UPLOAD_FILE_TO_SURVEY:
      return { ...state, isFileUploading: false };
    case t.REQUEST_UPLOAD_FILE_TO_QUESTION:
      return { ...state, isFilesInQuestionLoading: true };
    case t.RECEIVE_UPLOAD_FILE_TO_QUESTION:
      return { ...state, isFilesInQuestionLoading: false };
    case t.REQUEST_ATTACH_FILE_TO_QUESTION:
      return { ...state, isFileAttaching: true };
    case t.RECEIVE_ATTACH_FILE_TO_QUESTION: {
      const attachFileToQuestions = (data) => {
        if (data.length > 0) {
          data.forEach((ques) => {
            if (ques.id === action.questionId) {
              ques.files.push(action.file);
            }
          });
        }
      };
      const newSection = JSON.parse(JSON.stringify(state.section));
      parseSection(newSection, attachFileToQuestions);
      return {
        ...state,
        section: newSection || state.section,
        isFileAttaching: false,
      };
    }
    case t.REQUEST_DETACH_FILE_FROM_QUESTION:
      return { ...state, isFileDetaching: true };
    case t.RECEIVE_DETACH_FILE_FROM_QUESTION: {
      const detachFileFromQuestions = (data) => {
        if (data.length > 0) {
          data.forEach((ques) => {
            if (ques.id === action.questionId) {
              _.remove(ques.files, (file) => file.id === action.fileId);
            }
          });
        }
      };
      const newSection = JSON.parse(JSON.stringify(state.section));
      parseSection(newSection, detachFileFromQuestions);
      return {
        ...state,
        section: newSection || state.section,
        isFileDetaching: false,
      };
    }
    case t.REQUEST_DELETE_FILE:
      return { ...state, isFileDeleting: true };
    case t.RECEIVE_DELETE_FILE:
      return { ...state, isFileDeleting: false };
    case t.REQUEST_EDIT_FILE:
      return { ...state, isFileEditing: true };
    case t.RECEIVE_EDIT_FILE:
      return { ...state, isFileEditing: false };
    case t.REQUEST_UPDATE_FILE:
      return { ...state, isFileUpdating: true };
    case t.RECEIVE_UPDATE_FILE:
      return { ...state, isFileUpdating: false };
    case t.REQUEST_SAVE_ANSWERS:
      return { ...state, isAnswerSaving: true };
    case t.RECEIVE_SAVE_ANSWERS:
      return { ...state, isAnswerSaving: false };
    case t.REQUEST_ADD_SECTION:
      return { ...state, isSectionAdding: true };
    case t.RECEIVE_ADD_SECTION:
      return { ...state, isSectionAdding: false };
    case t.REQUEST_EDIT_SECTION:
      return { ...state, isSectionEditing: true };
    case t.RECEIVE_EDIT_SECTION: {
      if (action.surveyId && String(action.surveyId) === String(state.id)) {
        let newSection;
        let newSections;
        if (
          action.data &&
          Object.keys(action.data) &&
          Object.keys(action.data).length > 0
        ) {
          newSections = JSON.parse(JSON.stringify(state.sections));
          newSections.forEach((sec) => {
            if (action.sectionId && action.sectionId === sec.id) {
              Object.keys(action.data).forEach((key) => {
                // eslint-disable-next-line no-param-reassign
                sec[key] = action.data[key];
              });
            }
          });
          if (action.sectionId && action.sectionId === state.section.id) {
            newSection = JSON.parse(JSON.stringify(state.section));
            Object.keys(action.data).forEach((key) => {
              newSection[key] = action.data[key];
            });
          }
        }
        return {
          ...state,
          isSectionEditing: false,
          section: newSection || state.section,
          sections: newSections || state.sections,
        };
      }
      return { ...state, isSectionEditing: false };
    }
    case t.REQUEST_TEMPLATE:
      return { ...state, isTemplateLoading: true };
    case t.RECEIVE_TEMPLATE:
      return { ...state, isTemplateLoading: false, templates: action.data };
    case t.REQUEST_INDUSTRIES:
      return { ...state, isIndustriesLoading: true };
    case t.RECEIVE_INDUSTRIES:
      return {
        ...state,
        isIndustriesLoading: false,
      };
    case t.REQUEST_PHOTOGRAPHIC_SCHEDULE:
      return { ...state, isPhotographicScheduleLoading: true };
    case t.RECEIVE_PHOTOGRAPHIC_SCHEDULE:
      return {
        ...state,
        isPhotographicScheduleLoading: false,
        photographicSchedule: action.data,
      };
    case t.SET_PAGINATION:
      return { ...state, pagination: action.data };
    default:
      return state;
  }
};
