/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form, Input, Button } from 'antd';
import './SurveyForm.scss';

const { TextArea } = Input;

const SurveyForm = ({
  fields = [],
  initialValues = {},
  onCancel,
  onSubmit,
  cancelText = 'Cancel',
  submitText = 'Submit',
  title,
  description,
}) => {
  return (
    <div className="survey-form">
      <h1>{title}</h1>
      {description && <div className="survey-form-desc">{description}</div>}
      <Form
        colon={false}
        initialValues={initialValues}
        hideRequiredMark
        layout="vertical"
        onFinish={(values) => onSubmit(values)}
      >
        <div className="survey-form-fields">
          {fields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
            >
              {field.type === 'INPUT' ? (
                <Input />
              ) : field.type === 'TEXTAREA' ? (
                <TextArea />
              ) : null}
            </Form.Item>
          ))}
        </div>
        <div className="survey-form-actions">
          <Button
            type="default"
            size="large"
            className="sc-btn-cancel"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="sc-btn-secondary"
          >
            {submitText}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SurveyForm;
