/* eslint import/prefer-default-export: off */
import { message } from 'antd';
import axios from 'axios';
import * as t from './actionTypes';
import auth from '../../services/auth';

export const notify = (contents, type = 'error') => {
  const fn = message[type];
  if (!fn) {
    message.error('Invalid message type.');
  }
  fn(contents, 3);
};

export const initialize = () => async (dispatch) => {
  dispatch({ type: t.APP_INITIALIZING });

  axios.defaults.headers.common.Accept = 'application/json; v=1.0';

  if (!auth.isLoggedIn) {
    message.error('Session expired.');
  } else {
    auth.setAuthorizationHeader();
  }

  dispatch({ type: t.APP_INITIALIZED });
};
