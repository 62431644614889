/* eslint-disable radix */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Empty, Spin } from 'antd';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { fetchMediaLibrary } from '../../../actions';
import File from '../../../components/file';

const ACCEPTED_FILE_TYPE = { images: 'image', documents: 'document' };

const FileList = ({
  files,
  fetchMediaLibrary,
  pagination,
  isMediaLibraryLoading,
}) => {
  const [list, setList] = useState([]);
  const { surveyId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const location = useLocation();
  const urlPath = location.pathname.split('/');
  const fileType = urlPath[urlPath.length - 1];
  const page = query.get('page') || 1;

  useEffect(() => {
    if (!isMediaLibraryLoading) {
      const pageNumber = Number.isInteger(page)
        ? page
        : Number.parseInt(page) || 1;

      const fileTypeSelected =
        !fileType || fileType === 'all' || !ACCEPTED_FILE_TYPE[fileType]
          ? ''
          : ACCEPTED_FILE_TYPE[fileType] || pagination.type;

      const param = {
        type: fileTypeSelected,
        page: pageNumber,
      };
      fetchMediaLibrary(surveyId, { ...pagination, ...param });
    }
  }, [pagination, page]);

  useEffect(() => {
    setList(files);
  }, [files]);

  const renderFileList = () =>
    list && list.length > 0 ? (
      [...list]
        .sort(
          (a, b) =>
            new Date(b.created_on).getTime() - new Date(a.created_on).getTime(),
        )
        .map((item) => <File item={item} key={item.created_on} />)
    ) : (
      <div style={{ padding: '50px', margin: '0 auto' }}>
        <Empty description={false} />
      </div>
    );

  return isMediaLibraryLoading ? (
    <Spin size="large" tip="Loading..." style={{ margin: '0 auto' }} />
  ) : (
    renderFileList()
  );
};

const mapStateToProps = ({ survey }) => ({
  files: survey.mediaLibrary.data.files,
  pagination: survey.pagination,
  isMediaLibraryLoading: survey.isMediaLibraryLoading,
});

const mapDispatchToProps = {
  fetchMediaLibrary,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileList);
