import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

const useUnsavedChangesWarning = (
  message = 'Do you want to discard changes?',
) => {
  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    // Detecting browser closing
    window.onbeforeunload = (e) => {
      if (!isDirty) {
        return null;
      }
      // Custom strings doesn't work anymore in Chrome
      (e || window.event).returnValue = message;
      return message;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty, message]);

  const routePrompt = <Prompt when={isDirty} message={message} />;

  return [routePrompt, isDirty, () => setDirty(true), () => setDirty(false)];
};

export default useUnsavedChangesWarning;
