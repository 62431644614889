import axios from 'axios';
import { message } from 'antd';
import { BAD_REQUEST } from 'http-status-codes';
import * as t from './actionTypes';
import auth from '../../services/auth';

const MSG_ERROR =
  'Sorry, an unexpected error occurred. Please try again later.';
const MSG_LOGIN_FAIL = 'Incorrect username or password';

const API_LMI_AUTH = process.env.REACT_APP_API_LMI_AUTH;
const LMI_PROJECT_ID = 14;

/**
 * Internal actions
 */
const submit = () => ({
  type: t.LOGIN_SUBMITTED,
});

const fail = () => ({
  type: t.LOGIN_FAILED,
});

/**
 * Exported actions
 */
export const open = () => ({
  type: t.LOGIN_OPEN,
});

export const close = () => ({
  type: t.LOGIN_CLOSE,
});

export const login = (data) => async (dispatch) => {
  const { username, password } = data;
  const url = API_LMI_AUTH;
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  params.append('grant_type', 'password');
  params.append('client_id', LMI_PROJECT_ID);
  dispatch(submit());
  try {
    const resp = await axios.post(url, params.toString());
    const { access_token: token, expires_in: expiry } = resp.data;
    auth.login(token, username, expiry);
    dispatch(close());
  } catch ({ response }) {
    const { status } = response;
    switch (status) {
      case BAD_REQUEST:
        message.error(MSG_LOGIN_FAIL);
        dispatch(fail());
        break;
      default:
        message.error(MSG_ERROR);
        dispatch(fail());
        break;
    }
  }
};

export const logout = () => {
  auth.logout();
  return {
    type: t.LOGIN_CLOSE,
  };
};
