import React from 'react';
import Icon from '@ant-design/icons';

import { ReactComponent as CameraSvg } from './assets/camera.svg';
import { ReactComponent as CardSvg } from './assets/card.svg';
import { ReactComponent as DownloadSvg } from './assets/download.svg';
import { ReactComponent as PhotoSvg } from './assets/photo.svg';
import { ReactComponent as GallerySvg } from './assets/gallery.svg';
import { ReactComponent as PaperClipSvg } from './assets/paper-clip.svg';
import { ReactComponent as CheckSvg } from './assets/check.svg';
import { ReactComponent as AddSvg } from './assets/add.svg';
import { ReactComponent as UploadSvg } from './assets/upload.svg';

const Camera = (props) => <Icon component={CameraSvg} {...props} />;
const Card = (props) => <Icon component={CardSvg} {...props} />;
const Download = (props) => <Icon component={DownloadSvg} {...props} />;
const Photo = (props) => <Icon component={PhotoSvg} {...props} />;
const Gallery = (props) => <Icon component={GallerySvg} {...props} />;
const PaperClip = (props) => <Icon component={PaperClipSvg} {...props} />;
const Check = (props) => <Icon component={CheckSvg} {...props} />;
const Add = (props) => <Icon component={AddSvg} {...props} />;
const Upload = (props) => <Icon component={UploadSvg} {...props} />;

export {
  Camera,
  Card,
  Download,
  Photo,
  Gallery,
  PaperClip,
  Check,
  Add,
  Upload,
};
