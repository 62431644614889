/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { Button, Modal as AntModal } from 'antd';
import {
  EditOutlined,
  PictureOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import Modal from '../../../../components/modal';
import './style.scss';
import { fetchSurveyList, deleteSurvey } from '../../actions';
import {
  actions as formActions,
  SurveyForm,
} from '../../../../components/forms';

const { confirm } = AntModal;

const Card = ({
  history,
  data = {},
  currentPage,
  editSurvey,
  deleteSurvey,
  fetchSurveyList,
  match,
}) => {
  const [visible, setVisible] = useState(false);
  const onDelete = async (id) => {
    await deleteSurvey(id);
    await fetchSurveyList({
      page: currentPage,
    });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure you want to delete this survey?',
      icon: <ExclamationCircleOutlined />,
      content: 'Deleted survey cannot be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const openMedia = (id) => {
    history.push(`/surveys/${id}/mediaLibrary`);
  };

  return (
    <div className="my-survey-card">
      <div className="my-survey-card-info">
        <div
          className={classNames('my-survey-card-status', {
            complete: data.completedPercent === '100',
          })}
        >
          {data.completedPercent === '100' ? 'Completed' : 'In Process'}
        </div>
        <div className="my-survey-card-description">
          Created: {moment(data.created_on).format('DD/MM/YYYY')} | Modified:{' '}
          {moment(data.last_updated_on).format('DD/MM/YYYY')}
        </div>
      </div>
      <div className="my-survey-card-actions">
        <div className="my-survey-card-title">
          <Link to={`${match.url}/${data.id}`}>{data.title}</Link>
        </div>
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => {
            setVisible(true);
          }}
        >
          <span className="my-survey-card-action">EDIT</span>
        </Button>
        <Button
          type="link"
          icon={<PictureOutlined />}
          onClick={() => openMedia(data.id)}
        >
          <span className="my-survey-card-action">MEDIA LIBRARY</span>
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => showDeleteConfirm(data.id)}
        >
          <span className="my-survey-card-action">DELETE</span>
        </Button>
      </div>
      <Modal
        className="header-btn-modal"
        visible={visible}
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <SurveyForm
          fields={[
            {
              label: 'Survey Name',
              name: 'title',
              rules: [
                {
                  required: true,
                  message: 'Survey name is required.',
                },
              ],
              type: 'INPUT',
            },
            {
              label: 'Description',
              name: 'description',
              type: 'TEXTAREA',
            },
          ]}
          initialValues={data}
          onCancel={() => {
            setVisible(false);
          }}
          onSubmit={(values) => {
            editSurvey(data.id, values);
            setVisible(false);
          }}
          submitText="Edit"
          title="Edit Survey"
        />
      </Modal>
    </div>
  );
};

const mapDispatchToProps = {
  editSurvey: formActions.editSurvey,
  fetchSurveyList,
  deleteSurvey
};

export default withRouter(connect(null, mapDispatchToProps)(Card));
