/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React from 'react';
import { Drawer, Form, Button, Input, message, Tag, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { IMAGE_TYPES } from '../../../../shared/constant';
import defaultFile from '../../assets/file.png';
import {
  deleteFile,
  detachFileFromQuestion,
  fetchMediaLibrary,
  editFile,
  fetchSection,
  fetchPhotographicSchedule,
} from '../../actions';
import './style.scss';

const API_BASE = process.env.REACT_APP_API_BASE;
const { confirm } = Modal;

const DrawerForm = ({
  visible,
  setVisible,
  file,
  deleteFile,
  fetchFiles,
  detachFileFromQuestion,
  editFile,
  fetchSection,
  fetchPhotographicSchedule,
  pagination,
}) => {
  const [form] = Form.useForm();
  const { surveyId, sectionId } = useParams();
  const fileUrl = `${API_BASE}surveys/${surveyId}/files/${file.name}?quality=original`;
  const dimension =
    file && file.metadata && JSON.parse(file.metadata).dimension
      ? JSON.parse(file.metadata).dimension
      : null;

  window.addEventListener(
    'error',
    (e) => {
      if (e.target.tagName.toUpperCase() === 'IMG') {
        e.target.src = defaultFile;
      }
    },
    true,
  );

  const onSubmit = () => {
    form.submit();
  };

  const onDownload = () => {
    window.open(fileUrl);
  };

  const onClose = () => {
    setVisible(false);
  };

  const fetchAll = async () => {
    if (sectionId) {
      await Promise.all([
        fetchSection(surveyId, sectionId),
        fetchFiles(surveyId, pagination),
        fetchPhotographicSchedule(surveyId),
      ]);
    } else {
      await Promise.all([
        fetchFiles(surveyId, pagination),
        fetchPhotographicSchedule(surveyId),
      ]);
    }
  };

  const onDelete = async () => {
    try {
      await deleteFile(surveyId, file.id);
      await fetchAll();
    } catch (err) {
      message.error(err.message);
    }
    setVisible(false);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this file?',
      icon: <ExclamationCircleOutlined />,
      content: 'Deleted file cannot be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete();
      },
      onCancel() {},
    });
  };

  const onEdit = async (values) => {
    const data = {
      title: values.title || null,
      description: values.description || null,
    };
    try {
      const res = await editFile(surveyId, file.id, data);
      await fetchAll();
      message.success(res);
    } catch (err) {
      message.error(err.message);
    }
    setVisible(false);
  };

  const onTagClose = async (questionId, fileId) => {
    try {
      const res = await detachFileFromQuestion(surveyId, questionId, fileId);
      await fetchAll();
      message.success(res);
    } catch (err) {
      message.error(err.message);
    }
    setVisible(false);
  };

  return (
    <Drawer
      width={400}
      onClose={onClose}
      visible={visible}
      footer={
        <div>
          <Button onClick={onClose} className="btn-cancel" size="large">
            CANCEL
          </Button>
          <Button
            onClick={onSubmit}
            type="primary"
            className="sc-btn-secondary btn-primary"
            size="large"
          >
            SAVE
          </Button>
        </div>
      }
      className="drawer"
    >
      <div className="drawer-image">
        {IMAGE_TYPES.indexOf(file.content_type) > -1 ? (
          <img src={fileUrl} alt="file-img" className="file-img" />
        ) : (
          <img src={defaultFile} alt="file-img" className="file-img" />
        )}
      </div>
      <Form
        layout="vertical"
        hideRequiredMark
        className="drawer-form"
        form={form}
        onFinish={onEdit}
        initialValues={{ title: file.title, description: file.description }}
      >
        <div className="drawer-info">
          <div>
            <span className="drawer-info-label">Filename: </span>
            {file.name}
          </div>
          <div>
            <span className="drawer-info-label">Upload on: </span>
            {moment(file.created_on).format('LL')}
          </div>
          <div>
            <span className="drawer-info-label">Filesize: </span>
            {`${Math.floor(file.content_length / 1000)} kb`}
          </div>
          {dimension && (
            <div>
              <span className="drawer-info-label">Dimensions: </span>
              {`${dimension.width} x ${dimension.height} px`}
            </div>
          )}
        </div>
        <div className="drawer-action">
          <Button
            onClick={onDownload}
            ttype="primary"
            className="sc-btn-secondary btn-primary download"
          >
            DOWNLOAD
          </Button>
          {file.path && file.questions && file.questions.length > 0 && (
            <>
              <div className="drawer-action-label">Remove from report</div>
              <div className="file-tag-container">
                {file.questions.map((ques) => {
                  const path = ques.section
                    ? [ques.section.title, ques.label]
                    : [ques.label];
                  return (
                    <Tag
                      key={ques.id}
                      closable
                      onClose={() => {
                        onTagClose(ques.id, file.id);
                      }}
                      className="file-tag"
                    >
                      <span className="file-tag-name">{path.join('/')}</span>
                    </Tag>
                  );
                })}
              </div>
            </>
          )}
          <div>
            <Button
              onClick={showDeleteConfirm}
              danger
              type="link"
              className="delete"
            >
              Delete permanently
            </Button>
          </div>
        </div>
        <div className="drawer-form-label">DESCRIPTION</div>
        <Form.Item name="description">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  pagination: state.survey.pagination,
});

const mapDispatchToProps = {
  deleteFile,
  fetchFiles: fetchMediaLibrary,
  editFile,
  detachFileFromQuestion,
  fetchPhotographicSchedule,
  fetchSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerForm);
