/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useRouteMatch,
  useLocation,
  useParams,
  Switch,
  Route,
} from 'react-router-dom';
import _ from 'lodash';
import auth from '../../../../services/auth';
import Section from '../section';
import SectionCard from './components/sectionCard';
import SurveyLayout from '../../components/layout';
import ErrorBoundary from '../../../../components/error';
import {
  fetchSurvey,
  fetchMediaLibrary,
  fetchReport,
  fetchTemplate,
  fetchPhotographicSchedule,
} from '../../actions';
import { sectionsSelector, templatesSelector } from '../../selectors';
import './style.scss';

const SectionList = ({
  sections,
  templates,
  fetchTemplate,
  fetchSurvey,
  fetchMediaLibrary,
  fetchReport,
  fetchPhotographicSchedule,
  match,
}) => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [sectionList, setSectionList] = useState();
  const { surveyId } = useParams();

  useEffect(() => {
    const initialize = async () => {
      if (surveyId && auth.isLoggedIn) {
        try {
          await Promise.all([
            fetchSurvey(surveyId),
            fetchMediaLibrary(surveyId),
            fetchReport(surveyId),
            fetchPhotographicSchedule(surveyId),
          ]);
        } catch (err) {
          console.error(err);
        }
      }
    };
    initialize();
  }, [fetchSurvey, fetchMediaLibrary, fetchReport, match.url, surveyId]);

  useEffect(() => {
    if (sections) {
      const newSectionList = [
        ...sections,
        {
          id: 1,
          title: 'Document Library',
        },
      ];
      const lastBuildingindex = _.findLastIndex(newSectionList, (section) =>
        /^Building/i.test(section.title),
      );
      newSectionList.splice(lastBuildingindex + 1, 0, {
        id: 0,
        title: 'Add new Building',
      });
      setSectionList(newSectionList);
    }
  }, [sections]);

  useEffect(() => {
    if (!templates && auth.isLoggedIn) {
      fetchTemplate();
    }
  }, [templates, fetchTemplate]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={() => (
          <SurveyLayout>
            <ErrorBoundary>
              <div className="survey-list">
                {sectionList &&
                  sectionList.map((item) => (
                    <SectionCard
                      key={item.id}
                      className="survey-list-item"
                      section={item}
                    />
                  ))}
              </div>
            </ErrorBoundary>
          </SurveyLayout>
        )}
      />
      <Route path={`${path}/sections/:sectionId`} render={() => <Section />} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  sections: sectionsSelector(state),
  templates: templatesSelector(state),
});

const mapDispatchToProps = {
  fetchSurvey,
  fetchMediaLibrary,
  fetchReport,
  fetchTemplate,
  fetchPhotographicSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionList);
