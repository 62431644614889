/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import LoginForm from './LoginForm';
import { close } from './actions';
import './style.scss';

const LoginModal = ({ close, isOpen }) => (
  <Modal
    visible={isOpen}
    onCancel={() => close()}
    closable={false}
    footer={null}
    className="login-modal"
  >
    <div className="login-modal-logo-container">
      <img src="/images/logo.png" alt="logo" />
    </div>
    <LoginForm />
  </Modal>
);

LoginModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

LoginModal.defaultProps = {
  isOpen: false,
};

const mapStateToProps = ({ login }) => ({ isOpen: login.isOpen });

const mapDispatchToProps = { close };

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
