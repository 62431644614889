/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { fetchIndustries } from '../../../../actions';

const { Option } = Select;

const SearchBox = ({
  fetchIndustries,
  size,
  placeholder,
  form,
  item,
  ...props
}) => {
  const [industryList, setIndustryList] = useState([]);
  const [nextUrl, setNextUrl] = useState();
  const [fetching, setFetching] = useState(false);
  const [searching, setSearching] = useState(false);

  const parseLinkHeader = (header) => {
    if (!header || header.length === 0) {
      return {};
    }
    const parts = header.split(',');
    const links = {};
    parts.forEach((p) => {
      const section = p.split(';');
      if (section.length === 2) {
        const url = section[0].replace(/<(.*)>/, '$1').trim();
        const name = section[1].replace(/rel="(.*)"/, '$1').trim();
        links[name] = url;
      }
    });
    return links;
  };

  const loadMore = async () => {
    if (fetching || !nextUrl) {
      return;
    }
    setFetching(true);
    try {
      const res = await fetchIndustries(null, nextUrl);
      const {
        data,
        headers: { link },
      } = res;
      const parsedLink = parseLinkHeader(link);
      setIndustryList([...industryList, ...data]);
      setNextUrl(parsedLink.next);
    } catch (err) {
      setIndustryList([]);
      setNextUrl(null);
    }
    setFetching(false);
  };

  const onSearch = debounce(async (value) => {
    if (value === '') {
      setIndustryList([]);
      return;
    }
    setIndustryList([]);
    setSearching(true);
    try {
      const params = Number.isNaN(parseInt(value, 10))
        ? { q: value }
        : { code: value };
      const res = await fetchIndustries(params);
      const {
        data,
        headers: { link },
      } = res;
      const parsedLink = parseLinkHeader(link);
      setIndustryList(data);
      setNextUrl(parsedLink.next);
    } catch (err) {
      setNextUrl(null);
    }
    setSearching(false);
  }, 300);

  const onChange = (value) => {
    form.setFieldsValue({ [item.id]: value });
  };

  const onSelect = (value) => {
    const [code, name] = value.split('-');
    if (item.label === 'Risk Occupation') {
      form.setFieldsValue({ [item.id]: name });
      form.setFieldsValue({ [item.id + 1]: code });
    } else if (item.label === 'ANZSIC Code') {
      form.setFieldsValue({ [item.id]: code });
      form.setFieldsValue({ [item.id - 1]: name });
    }
  };

  const onPopupScroll = async (e) => {
    if (!fetching) {
      const element = e.target;
      if (
        element.scrollHeight - element.scrollTop <
        element.clientHeight + 10
      ) {
        loadMore();
      }
    }
  };

  const options = industryList
    .filter((item) => !item.alias)
    .map((item) => (
      <Option key={`${item.code}-${item.name}`}>
        {`${item.code} - ${item.name}`}
      </Option>
    ));

  return (
    <Select
      allowClear
      showSearch
      size={size}
      placeholder={placeholder}
      showArrow={false}
      filterOption={false}
      notFoundContent={searching ? <Spin /> : null}
      onSearch={onSearch}
      onChange={onChange}
      onSelect={onSelect}
      onPopupScroll={onPopupScroll}
      className="industry-search-box-select"
      dropdownClassName="industry-search-box-select-dropdown"
      {...props}
    >
      {options}
    </Select>
  );
};

SearchBox.propTypes = {
  size: PropTypes.oneOf(['large', 'default']),
  placeholder: PropTypes.string,
};

SearchBox.defaultProps = {
  size: 'default',
  placeholder: 'Search Industry',
};

const mapDispatchToProps = { fetchIndustries };

export default connect(null, mapDispatchToProps)(SearchBox);
