import * as t from './actionTypes';

const initialState = {
  isLoading: false,
  isOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.LOGIN_SUBMITTED:
      return { ...state, isLoading: true };
    case t.LOGIN_OPEN:
      return { ...state, isOpen: true };
    case t.LOGIN_FAILED:
      return { ...state, isOpen: true, isLoading: false };
    case t.LOGIN_CLOSE:
      return { ...state, isOpen: false, isLoading: false };
    default:
      return state;
  }
};
