/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import auth from '../../services/auth';
import { login } from './actions';

const LoginForm = ({ login, isLoading }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    try {
      login(values);
      form.resetFields();
    } catch (err) {
      message.error(err);
    }
  };

  if (auth.isLoggedIn) {
    return <Redirect to="/surveys" />;
  }

  return (
    <Form form={form} onFinish={onFinish} className="login-form">
      <Form.Item
        colon={false}
        name="username"
        rules={[{ required: true, message: 'Username is required.' }]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="Username"
          disabled={isLoading}
          spellCheck={false}
        />
      </Form.Item>
      <Form.Item
        colon={false}
        name="password"
        rules={[{ required: true, message: 'Password is required.' }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={isLoading}
          className="btn-main login-form-submit"
        >
          {isLoading ? 'Logging in' : 'Log in'}
        </Button>
      </Form.Item>
    </Form>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

LoginForm.defaultProps = {
  isLoading: false
};

const mapStateToProps = ({ login }) => ({ isLoading: login.isLoading });

const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
