/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import Home from './scenes/home';
import MySurvey from './scenes/mySurvey';
import ChangeRouteModal from './components/common';
import Survey from './scenes/survey';
import auth from './services/auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: {
              from: props.location,
            },
          }}
        />
      )
    }
  />
);

export default () => (
  <Router getUserConfirmation={ChangeRouteModal}>
    <Switch>
      <Route exact path="/" component={Home} />
      <PrivateRoute exact path="/surveys" component={MySurvey} />
      <PrivateRoute path="/surveys/:surveyId" component={Survey} />
    </Switch>
  </Router>
);
