/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Form,
  Button,
  Select,
  message,
  Spin,
  Empty,
  Upload,
  Pagination,
} from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import {
  SnippetsOutlined,
  FileImageOutlined,
  FileTextOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  fetchSection,
  fetchMediaLibrary,
  fetchPhotographicSchedule,
  attachFileToQuestion,
  uploadFileToSurvey,
  setPagination,
} from '../../../../actions';
import defaultFile from '../../../../assets/default.png';
import CameraModal from '../../../../components/cameraModal';
import {
  Check,
  Camera as CameraIcon,
  Upload as UploadIcon,
} from '../../../../../../components/icons';
import { DOCUMENT_TYPES, IMAGE_TYPES } from '../../../../../../shared/constant';
import './style.scss';

const API_BASE = process.env.REACT_APP_API_BASE;
const { Option } = Select;
const PAGE_SIZE = 30;

const MediaLibraryForm = ({
  question,
  filter,
  hide,
  cancelText = 'Cancel',
  submitText = 'Submit',
  all,
  image,
  document,
  totalCount,
  isLoading,
  isFileUploading,
  fetchMediaLibrary,
  fetchPhotographicSchedule,
  attachFileToQuestion,
  uploadFileToSurvey,
  setPagination,
}) => {
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isFileAttaching, setIsFileAttaching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchFileType, setFetchFileType] = useState(filter);
  const [fileCount, setFileCount] = useState({});
  const { surveyId } = useParams();

  const param = {
    page: currentPage,
    pageSize: PAGE_SIZE,
    type: fetchFileType,
  };

  window.addEventListener(
    'error',
    (e) => {
      if (e.target.tagName.toUpperCase() === 'IMG') {
        e.target.src = defaultFile;
      }
    },
    true,
  );

  useEffect(() => {
    setFileCount({ all: totalCount, image, document });
  }, [filter]);

  useEffect(() => {
    fetchMediaLibrary(surveyId, param);
  }, [surveyId, fetchMediaLibrary, currentPage]);

  useEffect(() => {
    if (all) {
      setList(all);
    }
  }, [all]);

  const handleChange = (value) => {
    switch (value) {
      case 'all':
        setFetchFileType('');
        break;
      case 'image':
        setFetchFileType('image');
        break;
      case 'document':
        setFetchFileType('document');
        break;
      default:
        setFetchFileType('');
    }
  };

  const handleSelect = (file) => {
    const index = _.findIndex(selected, { id: file.id });
    if (index > -1) {
      setSelected(
        _.filter(selected, (item) => {
          return item.id !== file.id;
        }),
      );
    } else {
      setSelected(selected.concat(file));
    }
  };

  const onFinish = async () => {
    const promises = [];
    setIsFileAttaching(true);
    selected.forEach((file) => {
      const index = _.findIndex(question.files, { id: file.id });
      if (index === -1) {
        promises.push(
          attachFileToQuestion(
            surveyId,
            question.id,
            {
              file_id: file.id,
            },
            file,
          ),
        );
      }
    });
    if (promises.length > 0) {
      try {
        await Promise.all(promises);
        setIsFileAttaching(false);
        fetchMediaLibrary(surveyId, param);
        fetchPhotographicSchedule(surveyId);
        message.success('File attached successfully.');
      } catch (err) {
        setIsFileAttaching(false);
        message.error(err.message);
      }
    }
    hide();
  };

  const onCancel = () => {
    hide();
  };

  const beforeUpload = (file) => {
    const isAcceptedFiletypes =
      IMAGE_TYPES.indexOf(file.type) > -1 ||
      DOCUMENT_TYPES.indexOf(file.type) > -1;
    if (!isAcceptedFiletypes) {
      message.error('The file type is not accepted.');
    }
    const TEN_M = 10 * 1024 * 1024;
    const isLt10M = file.size < TEN_M;
    if (!isLt10M) {
      message.error('Maximum upload file size: 10 MB.');
    }
    return isAcceptedFiletypes && isLt10M;
  };

  const uploadMedia = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await uploadFileToSurvey(surveyId, formData);
      fetchMediaLibrary(surveyId, param);
      onSuccess(null, file);
      message.success(res);
    } catch (err) {
      onError();
      message.error(err.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPagination({ ...param, page });
  };

  return (
    <div className="media-library-form">
      {isLoading ? (
        <div className="spin-container">
          <Spin size="large" tip="Loading..." />
        </div>
      ) : (
        <Form
          colon={false}
          hideRequiredMark
          layout="vertical"
          onFinish={onFinish}
        >
          <div className="media-library-form-fields">
            <div className="media-library-form-header">
              <div className="media-library-form-title">MEDIA LIBRARY</div>
              <div className="media-library-form-btn-container">
                <div className="media-library-form-btn">
                  <Button type="primary">
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      customRequest={uploadMedia}
                      multiple
                    >
                      {isFileUploading ? <LoadingOutlined /> : <UploadIcon />}
                    </Upload>
                  </Button>
                  <Button
                    type="primary"
                    className="sc-btn-secondary"
                    icon={<CameraIcon />}
                    onClick={() => {
                      setVisible(true);
                    }}
                  />
                </div>
                <CameraModal
                  visible={visible}
                  onOk={() => {
                    setVisible(false);
                  }}
                  onCancel={() => {
                    setVisible(false);
                  }}
                />
                <div className="media-library-form-filter">
                  <Select
                    size="large"
                    defaultValue={filter}
                    style={{ width: 150 }}
                    onChange={handleChange}
                  >
                    <Option value="all">
                      <SnippetsOutlined className="media-library-form-filter-icon" />
                      All Files
                    </Option>
                    <Option value="image">
                      <FileImageOutlined className="media-library-form-filter-icon" />
                      All Images
                    </Option>
                    <Option value="document">
                      <FileTextOutlined className="media-library-form-filter-icon" />
                      All Documents
                    </Option>
                  </Select>
                </div>
              </div>
            </div>
            <div>
              {list && list.length > 0 ? (
                <div className="media-library-form-container">
                  <div className="media-library-form-file-container">
                    {[...list]
                      .sort(
                        (a, b) =>
                          new Date(b.created_on).getTime() -
                          new Date(a.created_on).getTime(),
                      )
                      .map((item) => (
                        <span key={item.id}>
                          <div
                            className="media-library-form-file"
                            onClick={() => {
                              handleSelect(item);
                            }}
                          >
                            {IMAGE_TYPES.indexOf(item.content_type) > -1 ? (
                              <img
                                src={`${API_BASE}surveys/${surveyId}/files/${item.name}`}
                                alt="media-library"
                              />
                            ) : (
                              <img src={defaultFile} alt="media-library" />
                            )}
                            <Check
                              className={classNames(
                                'media-library-form-file-check',
                                {
                                  selected:
                                    _.findIndex(selected, { id: item.id }) > -1,
                                },
                              )}
                            />
                          </div>
                          <div className="media-library-form-file-name">
                            {item.name}
                          </div>
                        </span>
                      ))}
                  </div>
                  {fileCount && (
                    <Pagination
                      current={currentPage}
                      onChange={handlePageChange}
                      total={fileCount[filter]}
                      pageSize={PAGE_SIZE}
                      hideOnSinglePage
                      showSizeChanger={false}
                    />
                  )}
                </div>
              ) : (
                <div className="empty-container">
                  <Empty description="No Item" />
                </div>
              )}
            </div>
          </div>

          <div className="media-library-form-button-container">
            {isFileAttaching ? (
              <Spin tip="Attaching..." />
            ) : (
              <div className="media-library-form-actions">
                <Button
                  type="default"
                  size="large"
                  className="sc-btn-cancel"
                  onClick={onCancel}
                >
                  {cancelText}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="sc-btn-secondary"
                >
                  {submitText}
                </Button>
              </div>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  all: state.survey.mediaLibrary.data.files,
  totalCount: state.survey.mediaLibrary.data.total,
  image: state.survey.mediaLibrary.data.counts.image,
  document: state.survey.mediaLibrary.data.counts.document,
  isLoading: state.survey.isLoading,
  isFileUploading: state.survey.isFileUploading,
});

const mapDispatchToProps = {
  attachFileToQuestion,
  fetchSection,
  fetchMediaLibrary,
  uploadFileToSurvey,
  fetchPhotographicSchedule,
  setPagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibraryForm);
