/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout as AntLayout, Menu, Affix, Badge, Empty } from 'antd';
import File from '../../components/file';
import { photographicScheduleSelector } from '../../selectors';
import './style.scss';

const { Content, Sider } = AntLayout;

const PhotographicSchedule = ({ photographicSchedule, location }) => {
  const [fileList, setFileList] = useState();
  const [selectedKey, setSelectedKey] = useState();
  const [affixTop, setAffixTop] = useState();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sectionTitle = urlParams.get('section');
    if (photographicSchedule && photographicSchedule.length > 0) {
      setSelectedKey(photographicSchedule[0].title);
      setFileList(photographicSchedule[0]);
    } else {
      setFileList(null);
    }
    if (sectionTitle) {
      photographicSchedule.forEach((section) => {
        if (String(section.title) === sectionTitle) {
          setSelectedKey(sectionTitle);
          setFileList(section);
        }
      });
    }
  }, [location.search, photographicSchedule, photographicSchedule.length]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setAffixTop(234);
        setCollapsed(false);
      } else {
        setAffixTop(100);
        setCollapsed(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  const handleClick = (e) => {
    photographicSchedule.forEach((section) => {
      if (String(section.title) === e.key) {
        setFileList(section);
        setSelectedKey(section.title);
      }
    });
  };

  return fileList ? (
    <AntLayout className="photographic-schedule">
      <Sider
        collapsed={collapsed}
        onCollapse={setCollapsed}
        collapsedWidth={60}
        width={280}
      >
        <Affix offsetTop={affixTop}>
          <h1 className="photographic-schedule-title">PHOTOGRAPHIC SCHEDULE</h1>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleClick}
          >
            {photographicSchedule.map((section) => {
              return section.files && section.files.length > 0 ? (
                <Menu.Item key={section.title}>
                  <div>
                    {section.title.toUpperCase()}{' '}
                    <Badge
                      count={section.files.length}
                      className="menu-badge"
                    />
                  </div>
                </Menu.Item>
              ) : null;
            })}
          </Menu>
        </Affix>
      </Sider>
      <Content className="photographic-schedule-content">
        <div className="photographic-schedule-page">
          <h2 className="photographic-schedule-section-title">
            {fileList.title}
          </h2>
          <div className="photographic-schedule-file-container">
            {fileList.files.map((file) => (
              <File
                key={`${file.questionId}${file.id}`}
                className="photographic-schedule-file"
                id={`section-detail-form_${file.questionId}${file.id}`}
                item={file}
                fileSectionId={fileList.id}
              />
            ))}
          </div>
          <div className="photographic-schedule-note">
            <h3 className="note-label">ENDNOTE</h3>
            {fileList.files.map((file) => {
              return file.description ? (
                <div key={`${file.questionId}${file.id}`} className="note-item">
                  <span className="file-name">{file.name}</span>
                  <span>{`: `}</span>
                  <span className="file-desc">{file.description}</span>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </Content>
    </AntLayout>
  ) : (
    <Empty description="No Item" />
  );
};

const mapStateToProps = (state) => ({
  photographicSchedule: photographicScheduleSelector(state),
});

export default withRouter(connect(mapStateToProps, null)(PhotographicSchedule));
