export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs
  const byteString = atob(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};

const loadImage = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'use-credentials');
    img.onload = () => resolve(img);
    img.onerror = () => reject();
    img.src = url;
  });

export const getDataUri = async (url) => {
  const img = await loadImage(url);
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  canvas.getContext('2d').drawImage(img, 0, 0);
  return canvas.toDataURL('image/png');
};

export const getContentType = (fileName) => {
  if (fileName.split('.').pop() === 'docx') {
    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  }
  if (fileName.split('.').pop() === 'doc') {
    return 'application/msword';
  }
  if (fileName.split('.').pop() === 'txt') {
    return 'text/plain';
  }
  if (fileName.split('.').pop() === 'csv') {
    return 'text/csv';
  }
  if (fileName.split('.').pop() === 'pdf') {
    return '	application/pdf';
  }
  if (fileName.split('.').pop() === 'xls') {
    return 'application/vnd.ms-excel';
  }
  if (fileName.split('.').pop() === 'xlsx') {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  if (
    fileName.split('.').pop() === 'jpeg' ||
    fileName.split('.').pop() === 'jpg'
  ) {
    return 'image/jpeg';
  }
  if (fileName.split('.').pop() === 'png') {
    return 'image/png';
  }
  if (fileName.split('.').pop() === 'gif') {
    return 'image/gif';
  }
  return '';
};
