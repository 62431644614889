/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, Button, message, Spin } from 'antd';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import './style.scss';

const CameraForm = ({
  onCancel,
  onSubmit,
  cancelText = 'Cancel',
  submitText = 'Submit',
}) => {
  const [dataUri, setDataUri] = useState('');
  const { isFileUploading, isFilesInQuestionLoading, isMediaLibraryLoading } =
    useSelector((state) => state.survey);

  const handleTakePhotoAnimationDone = (uri) => {
    setDataUri(uri);
  };

  const handleCameraError = () => {
    message.error('Cannot open the camera.');
    onCancel();
  };

  return (
    <div className="camera-form">
      <Form
        colon={false}
        hideRequiredMark
        layout="vertical"
        onFinish={(values) => onSubmit(dataUri, values.description)}
      >
        <div className="camera-form-fields">
          {dataUri ? (
            <img src={dataUri} alt="previw-img" />
          ) : (
            <Form.Item
              name="photo"
              rules={[{ required: true, message: 'Please take a photo.' }]}
              className="camera-form-photo"
            >
              <Camera
                onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                onCameraError={handleCameraError}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
              />
            </Form.Item>
          )}
          <Form.Item
            label="DESCRIPTION"
            name="description"
            className="camera-form-desc"
          >
            <Input />
          </Form.Item>
          <div className="camera-form-note">
            <div className="camera-form-title">
              <span className="is-highlighted">Note:</span>
            </div>
            <div className="camera-form-note-desc">
              The image will save in you report
              <span className="is-highlighted"> Media Library </span>
              for later consideration and use. You are also able to enter it
              directly in your report
              <span className="is-highlighted"> Photographic Schedule</span>.
            </div>
          </div>
        </div>
        <div className="camera-form-button-container">
          {isFileUploading ||
          isFilesInQuestionLoading ||
          isMediaLibraryLoading ? (
            <Spin tip="Saving..." />
          ) : (
            <div className="camera-form-actions">
              <Button
                type="default"
                size="large"
                className="sc-btn-cancel"
                onClick={onCancel}
              >
                {cancelText}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="sc-btn-secondary"
              >
                {submitText}
              </Button>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CameraForm;
