import React from 'react';
import Cover from '../cover';
import BackCover from '../backCover';
import ReportSections from '../reportSections';
import PhotographicSchedule from '../photographicSchedule';
import './style.scss';

export const Main = ({ photoList }) => {
  return (
    <>
      <main className="report-preview-main">
        <ReportSections />
        {photoList && photoList.length > 0 && (
          <PhotographicSchedule photoList={photoList} />
        )}
      </main>
      <BackCover />
    </>
  );
};

export default ({ photoList }) => {
  return (
    <article className="report-preview">
      <Cover />
      <Main photoList={photoList} />
    </article>
  );
};
