/* eslint-disable no-nested-ternary */
import { createSelector } from 'reselect';
import parse from 'parse-link-header';
import _ from 'lodash';
import moment from 'moment';
import { DOCUMENT_TYPES, IMAGE_TYPES } from '../../shared/constant';

export const mediaLibrarySelector = (state) => state.survey.mediaLibrary;

export const photographicSelector = (state) =>
  state.survey.photographicSchedule;

export const sectionsSelector = (state) =>
  state.survey.sections && state.survey.sections.length > 0
    ? [...state.survey.sections].sort((a, b) => a.seq - b.seq)
    : [];

export const photographicScheduleIdSelector = createSelector(
  sectionsSelector,
  (sections) => {
    let photographicScheduleId = null;
    if (sections && sections.length > 0) {
      sections.forEach((section) => {
        if (section.title === 'Photographic Schedule') {
          photographicScheduleId = section.id;
        }
      });
    }
    return photographicScheduleId;
  },
);

export const sectionSelector = (state) => state.survey.section;

export const reportSelector = (state) => state.survey.report;

export const reportSectionsSelector = createSelector(reportSelector, (report) =>
  report && report.sections
    ? [...report.sections].sort((a, b) => a.seq - b.seq)
    : null,
);

export const initialAnswersSelector = createSelector(
  sectionSelector,
  (section) => {
    const answers = {};
    const parseQuestionsAnswers = (data) => {
      if (data.length > 0) {
        data.forEach((ques) => {
          if (ques.answer) {
            try {
              const answer =
                ques.type.normalized_name === 'DATE'
                  ? moment(JSON.parse(ques.answer))
                  : ques.type.normalized_name === 'DATE RANGE'
                  ? JSON.parse(ques.answer).map((date) => moment(date))
                  : JSON.parse(ques.answer);
              answers[ques.id] = answer;
            } catch {
              console.log(`${ques.label} answer parsing error!`);
            }
          }
        });
      }
    };

    const parseGroupsAnswers = (data) => {
      if (data.length > 0) {
        data.forEach((group) => {
          parseQuestionsAnswers(group.questions);
        });
      }
    };

    const parseSectionAnswers = (data) => {
      if (data) {
        parseGroupsAnswers(data.groups);
        parseQuestionsAnswers(data.questions);
        if (data.sections.length > 0) {
          data.sections.forEach((item) => {
            parseSectionAnswers(item);
          });
        }
      }
    };

    parseSectionAnswers(section);
    return answers;
  },
);

export const templatesSelector = (state) => state.survey.templates;

export const buildingTemplateIdSelector = createSelector(
  templatesSelector,
  (templates) => {
    if (templates && templates.sections) {
      return templates.sections.filter((item) => item.title === 'Building')[0]
        .id;
    }
    return null;
  },
);

export const imagesSelector = createSelector(
  mediaLibrarySelector,
  (mediaLibrary) =>
    mediaLibrary && mediaLibrary.data && mediaLibrary.data.files
      ? mediaLibrary.data.files.filter(
          (item) => IMAGE_TYPES.indexOf(item.content_type) > -1,
        )
      : [],
);

export const documentsSelector = createSelector(
  mediaLibrarySelector,
  (mediaLibrary) =>
    mediaLibrary && mediaLibrary.data && mediaLibrary.data.files
      ? mediaLibrary.data.files.filter(
          (item) => DOCUMENT_TYPES.indexOf(item.content_type) > -1,
        )
      : [],
);

export const linkSelector = createSelector(
  mediaLibrarySelector,
  (mediaLibrary) =>
    mediaLibrary && mediaLibrary.headers && mediaLibrary.headers.link
      ? parse(mediaLibrary.headers.link)
      : null,
);

export const photographicScheduleSelector = createSelector(
  photographicSelector,
  (photographic) => {
    const photographicSchedule = [];
    const amendedFiles = [];

    photographic.files.forEach((file) => {
      file.questions.forEach((question) => {
        const path = [question.label];
        let sectionId;
        let sectionTitle;
        const parseSection = (section) => {
          path.push(section.title);
          if (section.parent) {
            parseSection(section.parent);
          } else {
            sectionId = section.id;
            sectionTitle = section.title;
          }
        };
        if (question.group) {
          path.push(question.group.label);
          if (question.group.section) {
            parseSection(question.group.section);
          }
        } else if (question.section) {
          parseSection(question.section);
        }
        const sectionFile = {
          ...file,
          path,
          sectionId,
          sectionTitle,
          questionId: question.id,
        };
        amendedFiles.push(sectionFile);
      });
    });
    const sectionFiles = _.groupBy(amendedFiles, 'sectionTitle');
    Object.keys(sectionFiles).forEach((key) => {
      photographicSchedule.push({
        id: sectionFiles[key][0].sectionId,
        title: key,
        files: sectionFiles[key],
      });
    });
    return photographicSchedule;
  },
);
