import axios from 'axios';
import * as t from './actionTypes';

const API_BASE = process.env.REACT_APP_API_BASE;

const startCreateSurvey = () => ({
  type: t.START_CREATE_SURVEY,
});

const finishCreateSurvey = (data) => ({
  type: t.FINISH_CREATE_SURVEY,
  data,
});

const startEditSurvey = () => ({
  type: t.START_EDIT_SURVEY,
});

const finishEditSurvey = (data) => ({
  type: t.FINISH_EDIT_SURVEY,
  data,
});

/* TODO: create "failed" action type at app level; dispatch when error occurs */

const createSurvey = (data) => async (dispatch) => {
  const url = `${API_BASE}surveys`;
  dispatch(startCreateSurvey());
  const { title, description } = data;
  const newData = description ? { title, description } : { title };
  try {
    const res = await axios.post(url, newData);
    dispatch(finishCreateSurvey(res.data));
  } catch (err) {
    dispatch(finishCreateSurvey());
  }
};

const editSurvey = (id, data) => async (dispatch) => {
  dispatch(startEditSurvey());
  const url = `${API_BASE}surveys/${id}`;
  try {
    await axios.patch(url, data);
    dispatch(
      finishEditSurvey({
        id,
        ...data,
      }),
    );
  } catch (err) {
    dispatch(finishEditSurvey(data));
  }
};

export { createSurvey, editSurvey };
