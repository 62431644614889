import React from 'react';
import PropTypes from 'prop-types';
import { List as AntList } from 'antd';
import Card from '../card';

const PAGE_SIZE = 30;
const List = ({
  loading,
  surveyList = [],
  currentPage,
  onPageChange,
  total,
}) => (
  <AntList
    loading={loading}
    bordered={false}
    dataSource={surveyList}
    locale={{ emptyText: 'No Survey' }}
    renderItem={(data) => (
      <Card key={data.id} data={data} currentPage={currentPage} />
    )}
    pagination={{
      hideOnSinglePage: true,
      current: currentPage,
      pageSize: PAGE_SIZE,
      onChange: onPageChange,
      total,
      showSizeChanger: false,
    }}
  />
);

List.propTypes = {
  loading: PropTypes.bool,
  surveyList: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

List.defaultProps = {
  loading: false,
};

export default List;
