import React from 'react';
import Modal from '../../../../../../components/modal';
import { SurveyForm } from '../../../../../../components/forms';

const AddBuildingModal = ({ onAdd, visible, onOk, onCancel }) => (
  <Modal visible={visible} onOk={onOk} onCancel={onCancel}>
    <SurveyForm
      fields={[
        {
          label: 'Building Name',
          name: 'title',
          rules: [
            {
              required: true,
              message: 'Building name is required.',
            },
          ],
          type: 'INPUT',
        },
        {
          label: 'Description',
          name: 'description',
          type: 'TEXTAREA',
        },
      ]}
      onCancel={onCancel}
      onSubmit={(values) => {
        onAdd({
          title: `Building: ${values.title}`,
          description: values.description,
        });
      }}
      submitText="Create"
      title="Add new Building"
    />
  </Modal>
);

export default AddBuildingModal;
