import React from 'react';
import { Layout as AntLayout, Spin } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Layout from '../../../../components/layout';
import SurveyHeader from '../header';
import './style.scss';

const { Content } = AntLayout;

const SurveyLayout = ({ children, fixed, isLoading, isSectionLoading }) => {
  const content =
    isLoading || isSectionLoading ? (
      <div className="survey spin-container">
        <Spin size="large" tip="Loading..." />
      </div>
    ) : (
      children
    );

  return (
    <Layout fixed>
      <AntLayout style={{ backgroundColor: '#f8f8f8' }}>
        <SurveyHeader fixed={fixed} />
        <Content className={classNames('survey-header-container', { fixed })}>
          {content}
        </Content>
      </AntLayout>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.survey.isLoading,
  isSectionLoading: state.survey.isSectionLoading,
});

export default connect(mapStateToProps, null)(SurveyLayout);
