import * as t from './actionTypes';
import { actionTypes as formActionTypes } from '../../components/forms';

const initialState = {
  isLoading: false,
  results: {
    data: [],
    total: 0,
  },
};
const PAGE_SIZE = 30;

export default (state = initialState, action) => {
  switch (action.type) {
    case t.REQUEST_SURVEY_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case t.RECEIVE_SURVEY_LIST:
      return {
        ...state,
        isLoading: false,
        results: action.data,
      };
    case formActionTypes.START_CREATE_SURVEY:
      return {
        ...state,
        isLoading: true,
      };
    case formActionTypes.FINISH_CREATE_SURVEY: {
      const newData = [...state.results.data];
      if (state.results && state.results.data.length >= PAGE_SIZE) {
        newData.unshift(action.data);
        newData.pop();
      } else {
        newData.unshift(action.data);
      }
      return {
        isLoading: false,
        results: {
          data: newData,
          total: state.results.total + 1,
        },
      };
    }
    case formActionTypes.START_EDIT_SURVEY:
      return {
        ...state,
        isLoading: true,
      };
    case formActionTypes.FINISH_EDIT_SURVEY: {
      const updated = [...state.results.data];
      const index = updated.findIndex((s) => s.id === action.data.id);
      if (index >= 0) {
        updated[index] = action.data;
        return {
          results: {
            data: [...updated],
            total: state.results.total,
          },
          isLoading: false,
        };
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case t.REQUEST_DELETE_SURVEY:
      return {
        ...state,
        isLoading: true,
      };
    case t.RECEIVE_DELETE_SURVEY: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
