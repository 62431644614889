import React, { Fragment, useState } from 'react';
import { Divider, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CameraModal from '../../../../components/cameraModal';
import MediaLibraryModal from '../mediaLibraryModal';
import {
  Gallery,
  PaperClip,
  Camera as CameraIcon,
} from '../../../../../../components/icons';

import './style.scss';

const SuffixButton = ({ type, question }) => {
  const [cameraModalVisible, setCameraModalVisible] = useState(false);
  const [documentModalVisible, setDocumentModalVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);

  const questionId = question ? question.id : null;
  const addOns = question ? question.add_ons : null;
  const fileName = question
    ? `${question.label.replace(/\s+/g, '_')}.png`
    : null;

  const addOnsMap = {
    1: (
      <Button
        type="link"
        className="camera-modal-btn"
        icon={<CameraIcon />}
        onClick={() => {
          setCameraModalVisible(true);
        }}
      />
    ),
    2: (
      <Button
        type="link"
        onClick={() => {
          setDocumentModalVisible(true);
        }}
        icon={<PaperClip />}
      />
    ),
    3: (
      <Button
        type="link"
        onClick={() => {
          setImageModalVisible(true);
        }}
        icon={<Gallery />}
      />
    ),
  };
  return (
    <div className="suffix-btn">
      {type === 'select' && <DownOutlined />}
      {addOns.length > 0 && (
        <>
          <Divider type="vertical" />
          {addOns.map((addOn) => {
            return <Fragment key={addOn.id}>{addOnsMap[addOn.id]}</Fragment>;
          })}
          <CameraModal
            questionId={questionId}
            visible={cameraModalVisible}
            onOk={() => {
              setCameraModalVisible(false);
            }}
            onCancel={() => {
              setCameraModalVisible(false);
            }}
            fileName={fileName}
          />
          <MediaLibraryModal
            filter="document"
            question={question}
            visible={documentModalVisible}
            onOk={() => {
              setDocumentModalVisible(false);
            }}
            onCancel={() => {
              setDocumentModalVisible(false);
            }}
          />
          <MediaLibraryModal
            filter="image"
            question={question}
            visible={imageModalVisible}
            onOk={() => {
              setImageModalVisible(false);
            }}
            onCancel={() => {
              setImageModalVisible(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default SuffixButton;
