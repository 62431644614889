import React from 'react';
import { Modal } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import './style.scss';

const { confirm } = Modal;
const modalIcon = (
  <div className="modal-icon">
    <QuestionOutlined />
  </div>
);

export default (message, callback) => {
  confirm({
    icon: modalIcon,
    className: 'sections-modal',
    content: (
      <div className="modal-content">
        <h1 className="modal-title">Leave This Page?</h1>
        <p className="modal-text">
          You have unsaved changes that will be lost if you decide to leave this
          page.
        </p>
      </div>
    ),
    width: '600px',
    maskClosable: true,
    okText: 'Leave This Page',
    cancelText: 'Stay on This Page',
    cancelButtonProps: { size: 'large', className: 'sc-btn-cancel' },
    okButtonProps: { size: 'large', className: 'sc-btn-secondary' },

    onOk() {
      callback(true);
    },
    onCancel() {
      callback(false);
    },
  });
};
