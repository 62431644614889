/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, useParams } from 'react-router-dom';
import AddBuildingModal from '../addBuildingModal';
import { Add as AddIcon } from '../../../../../../components/icons';
import { buildingTemplateIdSelector } from '../../../../selectors';
import { fetchSurvey, addSection } from '../../../../actions';
import './style.scss';

const sectionImageRootPath = '/images/sections';

const sectionImageMappings = {
  'general details': 'card-general-details.jpg',
  building: 'card-building.jpg',
  'external exposures': 'card-external-exposures.jpg',
  'business interruption': 'card-business-interruption.jpg',
  'management systems': 'card-management-systems.jpg',
  conclusion: 'card-conclusion.jpg',
  'photographic schedule': 'card-photographic-schedule.jpg',
  'document library': 'card-document-library.jpg',
};

const SectionCard = ({
  section,
  match,
  buildingTemplateId,
  fetchSurvey,
  addSection,
}) => {
  const [visible, setVisible] = useState(false);
  const { surveyId } = useParams();

  const onBuildingAdd = async (data) => {
    if (buildingTemplateId) {
      await addSection(surveyId, {
        template_section_id: buildingTemplateId,
        parent_section_id: null,
        title: 'Building',
        description: null,
        ...data,
      });
      await fetchSurvey(surveyId);
    }
  };

  return (
    <div className="survey-card-wrapper">
      <div className="survey-card-inner">
        {section.title.toLowerCase() === 'add new building' ? (
          <>
            <div
              className="survey-card add-new"
              role="button"
              tabIndex={0}
              onClick={() => {
                setVisible(true);
              }}
              onKeyPress={() => {
                setVisible(true);
              }}
            >
              <div className="survey-card-icon">
                <div className="circle">
                  <AddIcon />
                </div>
              </div>
              <h1>{section.title}</h1>
            </div>
            <AddBuildingModal
              onAdd={onBuildingAdd}
              visible={visible}
              onOk={() => {
                setVisible(false);
              }}
              onCancel={() => {
                setVisible(false);
              }}
            />
          </>
        ) : section.title.toLowerCase() === 'document library' ? (
          <Link to={`${match.url}/mediaLibrary/documents?page=1`}>
            <div
              className="survey-card"
              style={{
                backgroundImage: `url(${sectionImageRootPath}/${sectionImageMappings['document library']})`,
              }}
            >
              <h1>{section.title}</h1>
            </div>
          </Link>
        ) : (
          <Link to={`${match.url}/sections/${section.id}`}>
            <div
              className="survey-card"
              style={{
                backgroundImage: `url(${sectionImageRootPath}/${
                  sectionImageMappings[
                    /^Building/i.test(section.title)
                      ? 'building'
                      : section.title.toLowerCase()
                  ]
                })`,
              }}
            >
              <h1>{section.title}</h1>
              <h2>{section.description}</h2>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  buildingTemplateId: buildingTemplateIdSelector(state),
});

const mapDispatchToProps = { fetchSurvey, addSection };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionCard),
);
