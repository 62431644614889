/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import moment from 'moment';
import 'react-html5-camera-photo/build/css/index.css';
import Modal from '../../../../components/modal';
import {
  uploadFileToSurvey,
  uploadFileToQuestion,
  fetchMediaLibrary,
  fetchSection,
  fetchPhotographicSchedule,
} from '../../actions';
import CameraForm from './form';
import { dataURItoBlob } from '../../../../shared/util';
import './style.scss';

const CameraModal = ({
  uploadFileToSurvey,
  uploadFileToQuestion,
  fetchMediaLibrary,
  fetchSection,
  fetchPhotographicSchedule,
  pagination,
  title,
  questionId,
  visible,
  onOk,
  onCancel,
  fileName = `${title}_${moment().format('YYYYMMDDHHmm')}.png`,
}) => {
  const { surveyId, sectionId } = useParams();

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="80vw"
      style={{ maxWidth: '800px' }}
    >
      <CameraForm
        onCancel={onCancel}
        onSubmit={async (dataUri, description) => {
          const file = dataURItoBlob(dataUri);
          const formData = new FormData();
          formData.append('file', file, fileName);
          if (description) {
            formData.append('description', description);
          }
          try {
            let res;
            if (questionId && sectionId) {
              res = await uploadFileToQuestion(surveyId, questionId, formData);
              await Promise.all([
                fetchSection(surveyId, sectionId, true),
                fetchMediaLibrary(surveyId, pagination),
                fetchPhotographicSchedule(surveyId),
              ]);
            } else {
              res = await uploadFileToSurvey(surveyId, formData);
              await fetchMediaLibrary(surveyId, pagination);
            }
            message.success(res);
            onOk();
          } catch (err) {
            message.error(err.message);
            onCancel();
          }
        }}
        submitText="SAVE"
        cancelText="CANCEL"
        title="Take Photo"
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  title: state.survey.title,
  pagination: state.survey.pagination,
});

const mapDispatchToProps = {
  uploadFileToSurvey,
  uploadFileToQuestion,
  fetchMediaLibrary,
  fetchSection,
  fetchPhotographicSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraModal);
