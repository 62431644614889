import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import DrawerForm from '../drawer';
import { IMAGE_TYPES } from '../../../../shared/constant';
import defaultFile from '../../assets/file.png';
import './style.scss';

const API_BASE = process.env.REACT_APP_API_BASE;

const File = ({ item, fileSectionId, id }) => {
  const { surveyId } = useParams();
  const [visible, setVisible] = useState(false);

  window.addEventListener(
    'error',
    (e) => {
      if (e.target.tagName.toUpperCase() === 'IMG') {
        e.target.src = defaultFile;
      }
    },
    true,
  );

  const showDrawer = () => {
    setVisible(true);
  };

  return (
    <div className="file" id={id}>
      <div
        className="file-image"
        onClick={() => {
          showDrawer();
        }}
        onKeyPress={() => {
          showDrawer();
        }}
        role="button"
        tabIndex="0"
      >
        {IMAGE_TYPES.indexOf(item.content_type) > -1 ? (
          <img
            src={`${API_BASE}surveys/${surveyId}/files/${item.name}?quality='original'`}
            alt="media-library"
            className="file-img"
          />
        ) : (
          <img src={defaultFile} alt="media-library" className="file-img" />
        )}
      </div>
      {fileSectionId && item.path && (
        <HashLink
          scroll={(el) =>
            el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
          to={`/surveys/${surveyId}/sections/${fileSectionId}#section-detail-form_${item.questionId}`}
        >
          <div className="file-tag">{item.path.join('/')}</div>
        </HashLink>
      )}
      <div className="file-name">{item.name}</div>
      <DrawerForm setVisible={setVisible} visible={visible} file={item} />
    </div>
  );
};

export default File;
