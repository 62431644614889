/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

const TableField = ({ data }) => {
  const ANSWER_LENGTH_THRESHOLD = 78;
  // If you use server rendering,
  // keep in mind that neither useLayoutEffect nor useEffect can run until the JavaScript is downloaded.

  // If data has questions, it is a group;
  // if data has no questions, it is a single question.
  const orderedList = data.questions
    ? data.questions.sort((itemA, itemB) => itemA.seq - itemB.seq)
    : [];

  const parseAnswer = (question) =>
    question.answer
      ? question.type.normalized_name === 'DATE'
        ? moment(JSON.parse(question.answer)).format('LL')
        : question.type.normalized_name === 'DATE RANGE'
        ? JSON.parse(question.answer)
            .map((date) => moment(date).format('LT'))
            .join(' - ')
        : question.type.normalized_name === 'MULTIPLE CHOICE' &&
          Array.isArray(JSON.parse(question.answer))
        ? JSON.parse(question.answer).join(', ')
        : JSON.parse(question.answer)
      : null;

  const isWordWrap = (word, lengthThreshold) => word.length > lengthThreshold;

  return (
    <>
      {data.questions ? (
        <div className="table-group">
          <div className="group-header">{data.label}</div>
          <ul>
            {orderedList &&
              orderedList.map((question) => {
                const answer = parseAnswer(question);
                return (
                  <li className="table-row in-group-row" key={question.id}>
                    <div className="table-column">{question.label}</div>
                    <div
                      className={classNames('table-column answer', {
                        'is-word-wrap': isWordWrap(
                          answer,
                          ANSWER_LENGTH_THRESHOLD,
                        ),
                      })}
                    >
                      {answer}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      ) : (
        <li className="table-row">
          <div className="table-column">{data.label}</div>
          <div
            className={classNames('table-column answer', {
              'is-word-wrap': isWordWrap(
                parseAnswer(data),
                ANSWER_LENGTH_THRESHOLD,
              ),
            })}
          >
            {parseAnswer(data)}
          </div>
        </li>
      )}
    </>
  );
};

export default TableField;
