/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { Layout as AntLayout, Input } from 'antd';
import Layout from '../../components/layout';
import List from './components/list';
import { fetchSurveyList } from './actions';
import auth from '../../services/auth';
import './style.scss';
import banner from './assets/banner.png';

const { Content } = AntLayout;
const { Search } = Input;
// const { Option } = Select;

const MySurvey = ({ isLoading, results, fetchSurveyList }) => {
  // const [filterValue, setFilterValue] = useState();
  const [searchValue, setSearchValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_SIZE = 30;

  useEffect(() => {
    if (results && results.data.length === 0 && results.total > 0) {
      setCurrentPage((c) => c - 1);
    }
  }, [results]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      fetchSurveyList({
        title: searchValue,
        page: currentPage,
        pageSize: PAGE_SIZE,
        sort: 'last_updated_on',
        direction: 'desc',
      });
    }
  }, [searchValue, currentPage, fetchSurveyList]);

  const onSearch = debounce((value) => {
    setSearchValue(value);
    setCurrentPage(1);
  }, 500);

  const onPageChange = (page) => {
    window.scroll(0, 100);
    setCurrentPage(page);
  };

  return (
    <Layout>
      <div className="my-survey">
        <div style={{ backgroundImage: `url(${banner})` }} className="banner" />
        <div className="welcome">
          <h3>
            Welcome back,&nbsp;
            <em>{auth.session.username}!</em>
          </h3>
        </div>
        <AntLayout className="main-content">
          {/* <Affix offsetTop={48}>
            <Sider>
              <Menu mode="inline" defaultSelectedKeys={['1']}>
                <Menu.Item key="1">
                  <i className="fa fa-th" />
                  <span>All Surveys</span>
                </Menu.Item>
              </Menu>
            </Sider>
          </Affix> */}
          <Content>
            <div className="filter">
              <h2>Your recent surveys</h2>
              {/* <Select
                placeholder="Filter By"
                value={filterValue}
                className="sort-container"
                onChange={(value) => setFilterValue(value)}
              >
                <Option key="in-progress">In Progress</Option>
                <Option key="completed">Completed</Option>
              </Select> */}
              <Search
                placeholder="Search surveys by name"
                className="search-input"
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
            {results && (
              <List
                loading={isLoading}
                surveyList={[...results.data]}
                currentPage={currentPage}
                onPageChange={onPageChange}
                total={results.total}
              />
            )}
          </Content>
        </AntLayout>
      </div>
    </Layout>
  );
};

const mapStateToProps = ({ mySurvey }) => ({
  isLoading: mySurvey.isLoading,
  results: mySurvey.results,
});

const mapDispatchToProps = { fetchSurveyList };

export default connect(mapStateToProps, mapDispatchToProps)(MySurvey);
