import React from 'react';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';

const currentYear = new Date().getFullYear();
export default () => (
  <div className="footer-container">
    <div className="content text-center text-md-left">
      <Row>
        <Col xs={24} md={12} style={{ padding: '8px 0' }}>
          <h5 className="title">Useful links</h5>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a
                href="https://lmigroup.io/our-team/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Our Team
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://lmigroup.io/complaints/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Complaints
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://lmigroup.io/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={24} md={12} style={{ padding: '8px 0' }}>
          <h5 className="title">Keep Social</h5>
          <a
            href="https://twitter.com/LMIGroup"
            target="_blank"
            rel="noopener noreferrer"
            className="yellow-bg"
          >
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </a>
          <a
            href="https://www.facebook.com/LMI-Group-Pty-Ltd-144988182266476/"
            target="_blank"
            rel="noopener noreferrer"
            className="yellow-bg"
          >
            <FontAwesomeIcon icon={['fab', 'facebook-square']} />
          </a>

          <a
            href="https://www.youtube.com/user/LMIGROUP"
            target="_blank"
            rel="noopener noreferrer"
            className="yellow-bg"
          >
            <FontAwesomeIcon icon={['fab', 'youtube']} />
          </a>
        </Col>
      </Row>
    </div>
    <div className="footer-copyright text-center py-3">
      <div>
        &copy;
        {` 1999 - ${currentYear} LMI Group Pty Ltd. All Rights Reserved.`}
      </div>
    </div>
  </div>
);
