import { combineReducers } from 'redux';
import AppReducer from './components/app/reducer';
import LoginReducer from './components/login/reducer';
import MySurveyReducer from './scenes/mySurvey/reducer';
import SurveyReducer from './scenes/survey/reducer';

export default combineReducers({
  app: AppReducer,
  login: LoginReducer,
  survey: SurveyReducer,
  mySurvey: MySurveyReducer,
});
