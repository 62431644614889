import React from 'react';
import './style.scss';

const Title = ({ title }) => {
  const titleList = title.split(' ');
  const top = titleList[0];
  const bottom = titleList.slice(1, title.length - 1).join(' ');
  return (
    <div className="report-section-title">
      {titleList.length > 1 && <h2 className="title-top">{top}</h2>}
      <h2 className="title-bottom">{titleList.length > 1 ? bottom : top}</h2>
    </div>
  );
};

export default Title;
