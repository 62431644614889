/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Form,
  Input,
  DatePicker,
  Select,
  Col,
  TimePicker,
  Tag,
  Switch,
  Checkbox,
  message,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SearchBox from '../searchBox';
import SuffixButton from '../suffixButton';
import {
  detachFileFromQuestion,
  fetchSection,
  fetchMediaLibrary,
  fetchPhotographicSchedule,
} from '../../../../actions';
import './style.scss';

const Question = ({
  item,
  index,
  photographicScheduleId,
  sectionTitle,
  detachFileFromQuestion,
  fetchMediaLibrary,
  fetchPhotographicSchedule,
  form,
}) => {
  const [close, setClose] = useState(true);
  const [tagLoading, setTagLoading] = useState({});
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const { Option } = Select;
  const { TextArea } = Input;
  const { RangePicker } = TimePicker;
  const { surveyId } = useParams();

  const LABEL_LENGTH_THRESHOLD = 27;
  const oneRow =
    item.type.normalized_name === 'PARAGRAPH' ||
    item.label.length > LABEL_LENGTH_THRESHOLD;

  useEffect(() => {
    if (item.type.normalized_name === 'DATE RANGE') {
      const answers = JSON.parse(sessionStorage.getItem('answers'));
      if (answers) {
        sessionStorage.setItem(
          'answers',
          JSON.stringify({
            ...answers,
            [item.id]: item.answer,
          }),
        );
      } else {
        sessionStorage.setItem(
          'answers',
          JSON.stringify({ [item.id]: item.answer }),
        );
      }
      if (item.answer && item.answer !== '') {
        setClose(false);
      } else {
        setClose(true);
      }
    }
  }, [item.type.normalized_name, item.answer, item.id]);

  const onTagClose = async (questionId, fileId) => {
    try {
      setTagLoading({ [fileId]: true });
      await detachFileFromQuestion(surveyId, questionId, fileId);
      setTagLoading({});
      await fetchMediaLibrary(surveyId);
      await fetchPhotographicSchedule(surveyId);
      message.success('File detached successfully.');
    } catch (err) {
      setTagLoading({});
      message.error(err);
    }
  };

  const renderItem = (item) => {
    switch (item.type.normalized_name) {
      case 'INDUSTRY LOOKUP':
        return (
          <SearchBox
            size="large"
            form={form}
            item={item}
            placeholder={item.placeholder}
          />
        );
      case 'SHORT TEXT':
      case 'NUMBER':
        return (
          <Input
            placeholder={item.placeholder}
            size="large"
            style={{
              border: '0.4px solid #F6F6F6',
            }}
            suffix={
              item.add_ons.length > 0 ? (
                <SuffixButton type="input" question={item} />
              ) : null
            }
          />
        );
      case 'DATE':
        return (
          <DatePicker
            size="large"
            style={{
              border: '0.4px solid #F6F6F6',
              width: '100%',
            }}
          />
        );
      case 'PARAGRAPH':
        return (
          <TextArea
            placeholder={item.placeholder}
            autoSize={{ minRows: 6, maxRows: 8 }}
            style={{
              border: '0.4px solid #F6F6F6',
            }}
          />
        );
      case 'DATE RANGE':
        return (
          <RangePicker
            size="large"
            use12Hours
            format="h:mm A"
            style={{
              border: '0.4px solid #F6F6F6',
              width: '100%',
            }}
            disabled={close}
          />
        );
      case 'SELECT':
      case 'CHECKBOX':
        return (
          <Select
            size="large"
            placeholder={item.placeholder}
            allowClear
            suffixIcon={
              <div className="select-affix">
                <SuffixButton type="select" question={item} />
              </div>
            }
          >
            {item.default_options
              ? JSON.parse(item.default_options).map((option) => (
                  <Option key={option.name} value={option.name}>
                    {option.name}
                  </Option>
                ))
              : null}
          </Select>
        );
      case 'MULTIPLE CHOICE':
        return (
          <Select
            size="large"
            placeholder={item.placeholder}
            mode="tags"
            allowClear
          >
            {item.default_options
              ? JSON.parse(item.default_options).map((option) => (
                  <Option key={option.name} value={option.name}>
                    {option.name}
                  </Option>
                ))
              : null}
          </Select>
        );
      default:
        return null;
    }
  };

  return (
    <Col
      xs={24}
      sm={22}
      md={oneRow ? 22 : 11}
      lg={oneRow ? 22 : 11}
      className="question"
    >
      <h4 className="question-label">{item.label}</h4>
      <div
        className={classNames('question-content', {
          'has-tag': item.files && item.files.length > 0,
        })}
      >
        <Form.Item name={item.id}>{renderItem(item)}</Form.Item>
        <div className="file-tag-container">
          {item.files &&
            item.files.length > 0 &&
            item.files.map((file) => {
              return (
                <Tag
                  key={file.id}
                  closable={!tagLoading[file.id]}
                  icon={tagLoading[file.id] && <LoadingOutlined />}
                  onClose={() => {
                    onTagClose(item.id, file.id);
                  }}
                  className="file-tag"
                >
                  <HashLink
                    scroll={(el) =>
                      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                    to={`/surveys/${surveyId}/sections/${photographicScheduleId}?section=${sectionTitle}#section-detail-form_${item.id}${file.id}`}
                  >
                    <span className="file-tag-name">{file.name}</span>
                  </HashLink>
                </Tag>
              );
            })}
        </div>
        {item.type.normalized_name === 'DATE RANGE' && (
          <div className="range-picker-actions-container">
            <div className="range-picker-switch">
              <Switch
                checkedChildren="Open"
                unCheckedChildren="Close"
                defaultChecked={!close}
                checked={!close}
                onChange={(switchChecked) => {
                  if (switchChecked) {
                    setClose(false);
                  } else {
                    setClose(true);
                    setCheckBoxChecked(false);
                    form.setFieldsValue({ [item.id]: null });
                    const answers = JSON.parse(
                      sessionStorage.getItem('answers'),
                    );
                    sessionStorage.setItem(
                      'answers',
                      JSON.stringify({
                        ...answers,
                        [item.id]: null,
                      }),
                    );
                  }
                }}
              />
            </div>
            {index !== 0 && (
              <div className="range-picker-checkbox">
                <Checkbox
                  disabled={close}
                  checked={checkBoxChecked}
                  onChange={(e) => {
                    setCheckBoxChecked(e.target.checked);
                    if (e.target.checked) {
                      const answers = JSON.parse(
                        sessionStorage.getItem('answers'),
                      );
                      const previousDateRange = answers
                        ? answers[item.id - 1]
                        : null;
                      const answer =
                        previousDateRange && JSON.parse(previousDateRange)
                          ? JSON.parse(previousDateRange).map((date) =>
                              moment(date),
                            )
                          : null;
                      form.setFieldsValue({ [item.id]: answer });
                      sessionStorage.setItem(
                        'answers',
                        JSON.stringify({
                          ...answers,
                          [item.id]: previousDateRange,
                        }),
                      );
                    }
                  }}
                >
                  Same as previous day
                </Checkbox>
              </div>
            )}
          </div>
        )}
      </div>
    </Col>
  );
};

const mapDispatchToProps = {
  detachFileFromQuestion,
  fetchSection,
  fetchMediaLibrary,
  fetchPhotographicSchedule,
};

export default connect(null, mapDispatchToProps)(Question);
