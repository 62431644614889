/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const htmlTemplate = (htmlBody) =>
  htmlBody
    ? `
  <html>
    <head>
      <meta charset="utf-8">
      ${
        process.env.NODE_ENV === 'development'
          ? [...document.head.querySelectorAll('style')]
              .map((item) => item.outerHTML)
              .join('')
          : [...document.head.querySelectorAll('link[rel="stylesheet"]')]
              .map((item) => `<link href="${item.href}" rel="stylesheet" />`)
              .join('')
      }
      <title></title>
    </head>
    <body>
      ${htmlBody}
    </body>
  </html>`
    : null;

export const download = (content, cover, title) => () => {
  const url = process.env.REACT_APP_API_EXPORT;
  return axios.post(
    url,
    {
      content: htmlTemplate(content),
      frontMatter: htmlTemplate(cover),
      displayHeaderFooter: true,
      format: 'A4',
      margin: {
        top: '120px',
        bottom: '80px',
      },
      headerTemplate: `
          <div style="margin-left:auto; margin-right: 20px;
          padding:5px 50px 5px 0; text-align:left; font-size:10px; line-height:1.5;border-bottom:1px solid #238fc5;">
            <div style="color:#003262;font-weight:700;text-transform:uppercase">
              ${title}
            </div>
            <div style="color:#238fc5;font-weight:400;font-size: 8px;text-transform:uppercase">
              Risk Survey Report
            </div>
          </div>
        `,
      footerTemplate:
        '<div class="pageNumber" style="-webkit-print-color-adjust:exact;width:54px;height:16px;margin:0 0 0 auto;padding:0;line-height:16px;font-size:10px;text-align:center;color:#fff;background-color:#238fc5;" />',
    },
    { responseType: 'arraybuffer' },
  );
};
