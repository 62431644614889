/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Modal from '../modal';
import { open, logout } from '../login/actions';
import { SurveyForm, actions as formActions } from '../forms';
import auth from '../../services/auth';
import './style.scss';

const AntHeader = Layout.Header;

const Header = ({
  createSurvey,
  open,
  logout,
  history,
  fixed = false,
  newSurveyId,
}) => {
  const [creatSurveyModalVisible, setCreatSurveyModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const isRoot = history.location.pathname === '/';

  const userMenu = (
    <Menu className="header-user-menu">
      <Menu.Item className="header-user-menu-item">
        <a
          href="https://lmigroup.io/contact-us/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-envelope" />
          <span>Contact</span>
        </a>
      </Menu.Item>
      <Menu.Divider className="header-user-menu-divider" />
      <Menu.Item className="header-user-menu-item">
        <Button
          type="link"
          onClick={() => {
            logout();
            history.push('/');
          }}
        >
          <i className="fa fa-sign-out" />
          <span>Logout</span>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={classNames('header-container', { transparent: isRoot, fixed })}
    >
      <AntHeader className="header">
        <Link to="/" className="header-brand">
          <img src="/images/logo.png" alt="logo" />
        </Link>
        {auth.isLoggedIn ? (
          <>
            <Link to="/surveys" className="header-nav">
              MY SURVEYS
            </Link>
            <div className="header-btn-container">
              <Button
                type="primary"
                size="large"
                className="sc-btn-primary"
                onClick={() => {
                  setCreatSurveyModalVisible(true);
                }}
              >
                NEW SURVEY
              </Button>
            </div>
            <Modal
              className="header-btn-modal"
              visible={creatSurveyModalVisible}
              onOk={() => {
                setCreatSurveyModalVisible(false);
              }}
              onCancel={() => {
                setCreatSurveyModalVisible(false);
              }}
            >
              <SurveyForm
                fields={[
                  {
                    label: 'Survey Name',
                    name: 'title',
                    rules: [
                      {
                        required: true,
                        message: 'Survey name is required.',
                      },
                    ],
                    type: 'INPUT',
                  },
                  {
                    label: 'Description',
                    name: 'description',
                    type: 'TEXTAREA',
                  },
                ]}
                onCancel={() => {
                  setCreatSurveyModalVisible(false);
                }}
                onSubmit={(values) => {
                  createSurvey(values);
                  setCreatSurveyModalVisible(false);
                  setConfirmModalVisible(true);
                }}
                submitText="Create"
                title="Create Survey"
              />
            </Modal>
            {newSurveyId && (
              <Modal
                className="header-btn-modal"
                visible={confirmModalVisible}
                onOk={() => {
                  setConfirmModalVisible(false);
                }}
                onCancel={() => {
                  setConfirmModalVisible(false);
                }}
              >
                <SurveyForm
                  onCancel={() => {
                    setConfirmModalVisible(false);
                  }}
                  onSubmit={() => {
                    history.push(`/surveys/${newSurveyId}`);
                    setConfirmModalVisible(false);
                  }}
                  submitText="Go"
                  title="Survey Created"
                  description="Do you want to go to the newly created survey?"
                />
              </Modal>
            )}
            <Dropdown overlay={userMenu} trigger="click">
              <Avatar icon={<UserOutlined />} className="header-avatar" />
            </Dropdown>
          </>
        ) : (
          <>
            <Menu
              mode="horizontal"
              className="header-nav-menu"
              selectedKeys={[history.location.pathname]}
            >
              <Menu.Item key="/about" className="header-nav-menu-item">
                <a
                  href="https://lmigroup.io/company-profile/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ABOUT
                </a>
              </Menu.Item>
              <Menu.Item key="/contact" className="header-nav-menu-item">
                <a
                  href="https://lmigroup.io/contact-us/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CONTACT
                </a>
              </Menu.Item>
            </Menu>
            <Button
              type="primary"
              ghost
              className="header-btn"
              onClick={() => open()}
            >
              LOG IN
            </Button>
          </>
        )}
      </AntHeader>
      <div className="supporter" />
    </div>
  );
};

Header.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
    push: PropTypes.func.isRequired,
  }).isRequired,
  open: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ mySurvey }) => ({
  newSurveyId:
    mySurvey.results && mySurvey.results.data[0]
      ? mySurvey.results.data[0].id
      : null,
});

const mapDispatchToProps = {
  createSurvey: formActions.createSurvey,
  open,
  logout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
