export const REQUEST_SURVEY = 'survey/REQUEST_SURVEY';
export const RECEIVE_SURVEY = 'survey/RECEIVE_SURVEY';
export const REQUEST_REPORT = 'survey/REQUEST_REPORT';
export const RECEIVE_REPORT = 'survey/RECEIVE_REPORT';
export const REQUEST_SECTION = 'survey/REQUEST_SECTION';
export const RECEIVE_SECTION = 'survey/RECEIVE_SECTION';

export const REQUEST_MEDIA_LIBRARY = 'survey/REQUEST_MEDIA_LIBRARY';
export const RECEIVE_MEDIA_LIBRARY = 'survey/RECEIVE_MEDIA_LIBRARY';

export const REQUEST_FETCH_FILES_IN_SECTION =
  'survey/REQUEST_FETCH_FILES_IN_SECTION';
export const RECEIVE_FETCH_FILES_IN_SECTION =
  'survey/RECEIVE_FETCH_FILES_IN_SECTION';

export const REQUEST_FETCH_FILES_IN_QUESTION =
  'survey/REQUEST_FETCH_FILES_IN_QUESTION';
export const RECEIVE_FETCH_FILES_IN_QUESTION =
  'survey/RECEIVE_FETCH_FILES_IN_QUESTION';

export const REQUEST_UPLOAD_FILE_TO_SURVEY =
  'survey/REQUEST_UPLOAD_FILE_TO_SURVEY';
export const RECEIVE_UPLOAD_FILE_TO_SURVEY =
  'survey/RECEIVE_UPLOAD_FILE_TO_SURVEY';

export const REQUEST_UPLOAD_FILE_TO_QUESTION =
  'survey/REQUEST_UPLOAD_FILE_TO_QUESTION';
export const RECEIVE_UPLOAD_FILE_TO_QUESTION =
  'survey/RECEIVE_UPLOAD_FILE_TO_QUESTION';

export const REQUEST_ATTACH_FILE_TO_QUESTION =
  'survey/REQUEST_ATTACH_FILE_TO_QUESTION';
export const RECEIVE_ATTACH_FILE_TO_QUESTION =
  'survey/RECEIVE_ATTACH_FILE_TO_QUESTION';

export const REQUEST_DETACH_FILE_FROM_QUESTION =
  'survey/REQUEST_DETACH_FILE_FROM_QUESTION';
export const RECEIVE_DETACH_FILE_FROM_QUESTION =
  'survey/RECEIVE_DETACH_FILE_FROM_QUESTION';

export const REQUEST_DELETE_FILE = 'survey/REQUEST_DELETE_FILE';
export const RECEIVE_DELETE_FILE = 'survey/RECEIVE_DELETE_FILE';

export const REQUEST_EDIT_FILE = 'survey/REQUEST_EDIT_FILE';
export const RECEIVE_EDIT_FILE = 'survey/RECEIVE_EDIT_FILE';

export const REQUEST_UPDATE_FILE = 'survey/REQUEST_UPDATE_FILE';
export const RECEIVE_UPDATE_FILE = 'survey/RECEIVE_UPDATE_FILE';

export const REQUEST_SAVE_ANSWERS = 'survey/REQUEST_SAVE_ANSWERS';
export const RECEIVE_SAVE_ANSWERS = 'survey/RECEIVE_SAVE_ANSWERS';

export const REQUEST_ADD_SECTION = 'survey/REQUEST_ADD_SECTION';
export const RECEIVE_ADD_SECTION = 'survey/RECEIVE_ADD_SECTION';

export const REQUEST_EDIT_SECTION = 'survey/REQUEST_EDIT_SECTION';
export const RECEIVE_EDIT_SECTION = 'survey/RECEIVE_EDIT_SECTION';

export const REQUEST_TEMPLATE = 'survey/REQUEST_TEMPLATE';
export const RECEIVE_TEMPLATE = 'survey/RECEIVE_TEMPLATE';

export const REQUEST_INDUSTRIES = 'survey/REQUEST_INDUSTRIES';
export const RECEIVE_INDUSTRIES = 'survey/RECEIVE_INDUSTRIES';

export const REQUEST_PHOTOGRAPHIC_SCHEDULE =
  'survey/REQUEST_PHOTOGRAPHIC_SCHEDULE';
export const RECEIVE_PHOTOGRAPHIC_SCHEDULE =
  'survey/RECEIVE_PHOTOGRAPHIC_SCHEDULE';

export const SET_PAGINATION = 'survey/SET_PAGINATION';
