/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import _ from 'lodash';
import Title from '../title';
import './style.scss';

const PhotographicSchedule = ({ photoList }) => {
  const photoListRow = _.chunk(photoList, 2);

  const PhotoListRow = ({ file, index }) => {
    return (
      <div className="image-item" key={`${file.id}${file.questionId}`}>
        <img
          src={`${file.uri}`}
          alt="media-library"
          key={`${file.questionId}${file.id}`}
        />
        <div className="image-path">{file.path.join('/')}</div>
        <div className="image-info">
          <span className="image-name">{file.name}</span>
          {file.description && (
            <span className="image-desc">{file.description}</span>
          )}
          <span className="image-index">{`[${index + 1}]`}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="report-preview-photographic-schedule">
      <div className="section">
        <Title title="Photographic Schedule" />
        <div className="image-container">
          {photoListRow &&
            photoListRow.map((photoRow, index) => (
              <div className="image-row" key={photoRow[0].id}>
                <PhotoListRow file={photoRow[0]} index={index * 2} />
                {photoRow[1] && (
                  <PhotoListRow file={photoRow[1]} index={index * 2 + 1} />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PhotographicSchedule;
