/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Provider, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { Affix, Button, BackTop } from 'antd';
import { store } from '../../store';
import { download } from './actions';
import Download from '../../components/download';
import { getDataUri } from '../../shared/util';
import { photographicScheduleSelector } from '../survey/selectors';
import ReportPreview, { Main } from './components/reportPreview';
import Cover from './components/cover';
import './style.scss';

const API_BASE = process.env.REACT_APP_API_BASE;

const Report = ({ title, download, photographicSchedule }) => {
  const [photoList, setPhotoList] = useState([]);
  const { surveyId } = useParams();

  useEffect(() => {
    let unmounted = false;
    const getPhotoListPromise = async () => {
      const list = [];
      for (const section of photographicSchedule) {
        for (const file of section.files) {
          const uri = await getDataUri(
            `${API_BASE}surveys/${surveyId}/files/${file.name}?quality=small`,
          );
          list.push({ ...file, uri });
        }
      }
      return list;
    };

    const getPhotoList = async () => {
      const list = await getPhotoListPromise();

      if (!unmounted) {
        setPhotoList(list);
      }
    };

    getPhotoList();

    return () => {
      unmounted = true;
    };
  }, [photographicSchedule, surveyId]);

  const save = () => {
    const content = renderToStaticMarkup(
      <Provider store={store}>
        <Main photoList={photoList} />
      </Provider>,
    );
    const cover = renderToStaticMarkup(
      <Provider store={store}>
        <Cover />
      </Provider>,
    );
    return download(content, cover, title);
  };

  return (
    <div className="report-preview-container">
      <ReportPreview photoList={photoList} />
      <Affix
        offsetTop={50}
        style={{ position: 'absolute', top: '50px', right: '100px' }}
      >
        <Download
          fileName={`${title.replace(/\s+/g, '_')}_Risk_Survey_Report.pdf`}
          download={save}
        >
          {(onDownload, downloading) => (
            <Button
              shape="circle"
              size="large"
              className="report-save-btn"
              loading={downloading}
              onClick={onDownload}
            >
              <i className="fas fa-download" />
            </Button>
          )}
        </Download>
      </Affix>
      <BackTop />
    </div>
  );
};

const mapStateToProps = (state) => ({
  title: state.survey.title,
  photographicSchedule: photographicScheduleSelector(state),
});

const mapDispatchToProps = { download };

export default connect(mapStateToProps, mapDispatchToProps)(Report);
