/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Row, Divider, message } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import SurveyLayout from '../../components/layout';
import ErrorBoundary from '../../../../components/error';
import Error from './components/error';
import Question from './components/question';
import FormBtn from './components/formButton';
import NavBtn from './components/navButton';
import PhotographicSchedule from '../photographicSchedule';
import useUnsavedChangesWarning from '../../../../hooks/useUnsavedChangesWarning';
import {
  saveAnswers,
  fetchReport,
  fetchSection,
  editSection,
} from '../../actions';
import {
  sectionSelector,
  sectionsSelector,
  initialAnswersSelector,
  photographicScheduleIdSelector,
} from '../../selectors';
import './style.scss';

const Section = ({
  section,
  sections,
  initialAnswers,
  photographicScheduleId,
  saveAnswers,
  editSection,
  fetchSection,
  fetchReport,
}) => {
  const [form] = Form.useForm();
  const [isFooterAppearing, setIsFooterAppearing] = useState(false);
  const [isSectionSaving, setIsSectionSaving] = useState(false);
  const [preId, setPreId] = useState(null);
  const [nextId, setNextId] = useState(null);
  const [error, setError] = useState(null);
  const { surveyId, sectionId } = useParams();
  const containerEl = useRef();
  const [Prompt, isDirty, setDirty, setPristine] = useUnsavedChangesWarning();

  useEffect(() => {
    const initialize = async () => {
      if (surveyId && sectionId) {
        try {
          await fetchSection(surveyId, sectionId);
        } catch (err) {
          setError(<Error />);
        }
      }
    };
    initialize();
  }, [surveyId, sectionId, fetchSection]);

  useEffect(() => {
    form.setFieldsValue({ ...initialAnswers });
  }, [form, initialAnswers]);

  useEffect(() => {
    const index = _.findIndex(sections, { id: Number(sectionId) });
    if (index === -1) {
      setPreId(null);
      setNextId(null);
    } else {
      if (index === 0) {
        setPreId(null);
      } else {
        setPreId(sections[index - 1].id);
      }
      if (index === sections.length - 1) {
        setNextId(null);
      } else {
        setNextId(sections[index + 1].id);
      }
    }
  }, [sections, sectionId]);

  useEffect(() => {
    const onScroll = () => {
      if (containerEl.current) {
        const rect = containerEl.current.getBoundingClientRect();
        const viewportHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
        setIsFooterAppearing(rect.bottom <= viewportHeight);
      }
    };

    window.addEventListener('scroll', onScroll);

    if (containerEl.current) {
      onScroll();
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  const onFinish = async (values) => {
    const data = [];
    let buildingNameQuestion;
    let buildingTitle;
    let buildingSectionId;

    setIsSectionSaving(true);

    if (/Building/i.test(section.title)) {
      buildingNameQuestion = _.find(section.sections[0].questions, {
        reference: 'building',
      });
      buildingSectionId = section.id;
    }
    Object.keys(values).forEach((key) => {
      const answer = values[key] ? JSON.stringify(values[key]) : '';
      data.push({ Id: Number(key), Answer: answer });
      if (buildingNameQuestion && Number(key) === buildingNameQuestion.id) {
        if (values[key]) {
          buildingTitle = `Building: ${values[key]}`;
        } else {
          buildingTitle = `Building`;
        }
      }
    });
    try {
      if (buildingTitle && buildingSectionId) {
        await Promise.all([
          editSection(surveyId, buildingSectionId, {
            title: buildingTitle,
          }),
          saveAnswers(surveyId, data),
        ]);
      } else {
        await saveAnswers(surveyId, data);
      }
      await fetchReport(surveyId);
      setIsSectionSaving(false);

      message.success('Changes have been saved successfully.');
    } catch (err) {
      setIsSectionSaving(false);
      message.error(err.message);
    }

    setPristine();
  };

  const onFieldsChange = (changedFields) => {
    if (changedFields.length > 0) {
      setDirty();
      changedFields.forEach((field) => {
        const questionId = field.name ? field.name[0] : null;
        const answers = JSON.parse(sessionStorage.getItem('answers'));
        if (
          questionId &&
          answers &&
          Object.prototype.hasOwnProperty.call(answers, questionId)
        ) {
          const answer = JSON.stringify(field.value);
          sessionStorage.setItem(
            'answers',
            JSON.stringify({ ...answers, [questionId]: answer }),
          );
        }
      });
    }
  };

  const parseSection = (data, sectionTitle, isSubSection) => {
    if (!data) {
      return null;
    }

    if (data.title === 'Photographic Schedule') {
      return <PhotographicSchedule />;
    }

    const sectionData = [];
    if (data.groups.length > 0) {
      data.groups.forEach((group) => {
        sectionData.push({ type: 'group', data: group });
      });
    }
    if (data.questions.length > 0) {
      data.questions.forEach((question) => {
        sectionData.push({
          type: 'question',
          data: question,
        });
      });
    }
    if (data.sections.length > 0) {
      data.sections.forEach((section) => {
        sectionData.push({ type: 'section', data: section });
      });
    }

    sectionData.sort((a, b) => a.data.seq - b.data.seq);

    if (sectionData.length > 0) {
      return (
        <div key={data.id} className="section">
          {isSubSection ? (
            <h2 className="section-title">{data.title}</h2>
          ) : (
            <h2 className="section-title isHeadLine">{data.title}</h2>
          )}
          <div className="section-desc">{data.description}</div>
          <Row
            justify="start"
            gutter={[
              { xs: 4, sm: 8, md: 12, lg: 16, xl: 20, xxl: 24 },
              { xs: 4, sm: 8, md: 12, lg: 16, xl: 20, xxl: 24 },
            ]}
          >
            {sectionData.map((item, index) => {
              if (item.type === 'question') {
                return (
                  <Question
                    item={item.data}
                    photographicScheduleId={photographicScheduleId}
                    sectionTitle={sectionTitle}
                    key={item.data.id}
                    index={index}
                    form={form}
                  />
                );
              }
              if (item.type === 'group') {
                return (
                  <div key={item.data.id} className="group">
                    <h3 className="group-label">{item.data.label}</h3>
                    <Row
                      justify="start"
                      gutter={[
                        { xs: 4, sm: 8, md: 12, lg: 16, xl: 20, xxl: 24 },
                        { xs: 4, sm: 8, md: 12, lg: 16, xl: 20, xxl: 24 },
                      ]}
                    >
                      {[...item.data.questions]
                        .sort((a, b) => a.seq - b.seq)
                        .map((question, index) => (
                          <Question
                            item={question}
                            photographicScheduleId={photographicScheduleId}
                            sectionTitle={sectionTitle}
                            key={question.id}
                            index={index}
                            form={form}
                          />
                        ))}
                    </Row>
                    <Divider />
                  </div>
                );
              }
              if (item.type === 'section') {
                return parseSection(item.data, sectionTitle, true);
              }
              return null;
            })}
          </Row>
        </div>
      );
    }
    return null;
  };

  return (
    <SurveyLayout fixed>
      <ErrorBoundary>
        {error ||
          (section ? (
            <div className="section-detail-container" ref={containerEl}>
              <Form
                form={form}
                name={
                  section.title === 'Photographic Schedule'
                    ? 'photographic-schedule'
                    : 'section-detail-form'
                }
                onFinish={onFinish}
                onFieldsChange={onFieldsChange}
                initialValues={initialAnswers}
              >
                <div className="section-container">
                  {parseSection(section, section.title)}
                </div>
                <div
                  className={classNames('btn-container', {
                    'at-bottom': isFooterAppearing,
                  })}
                >
                  {section.title === 'Photographic Schedule' ? null : (
                    <FormBtn loading={isSectionSaving} />
                  )}

                  <NavBtn
                    preId={preId}
                    nextId={nextId}
                    form={form}
                    isDirty={isDirty}
                    setPristine={setPristine}
                  />
                </div>
              </Form>
              {Prompt}
            </div>
          ) : null)}
      </ErrorBoundary>
    </SurveyLayout>
  );
};

const mapStateToProps = (state) => ({
  section: sectionSelector(state),
  initialAnswers: initialAnswersSelector(state),
  sections: sectionsSelector(state),
  photographicScheduleId: photographicScheduleIdSelector(state),
});

const mapDispatchToProps = {
  saveAnswers,
  fetchReport,
  fetchSection,
  editSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
