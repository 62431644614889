import React from 'react';
import TableField from '../tableField';

const TableBulk = ({ data }) => {
  const list = [...data.questions, ...data.groups, ...data.sections];
  list.sort((a, b) => a.seq - b.seq);
  return (
    <div className="table-wrapper">
      <div className="table-header">{data.title}</div>
      {/* If bulk has sections, it is a section; */}
      {/* If bulk has no sections, it is a group or a single question. */}
      <ul className="table-section">
        {list.map((bulk) =>
          bulk.sections ? (
            <TableBulk data={bulk} key={bulk.id} />
          ) : (
            <TableField data={bulk} key={bulk.id} />
          ),
        )}
      </ul>
    </div>
  );
};

export default TableBulk;
