/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SectionList from './scenes/sectionList';
import MediaLibrary from './scenes/mediaLibrary';
import Report from '../report';
import './style.scss';

const Survey = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/mediaLibrary`} component={MediaLibrary} />
      <Route path={`${path}/preview`} component={Report} />
      <Route path={path} component={SectionList} />
    </Switch>
  );
};

export default Survey;
