export const REQUEST_SURVEY_LIST = 'mySurveys/REQUEST_SURVEY_LIST';
export const RECEIVE_SURVEY_LIST = 'mySurveys/RECEIVE_SURVEY_LIST';

export const REQUEST_CREATE_SURVEY = 'mySurveys/REQUEST_CREATE_SURVEY';
export const RECEIVE_CREATE_SURVEY = 'mySurveys/RECEIVE_CREATE_SURVEY';

export const REQUEST_EDIT_SURVEY = 'mySurveys/REQUEST_EDIT_SURVEY';
export const RECEIVE_EDIT_SURVEY = 'mySurveys/RECEIVE_EDIT_SURVEY';

export const REQUEST_DELETE_SURVEY = 'mySurveys/REQUEST_DELETE_SURVEY';
export const RECEIVE_DELETE_SURVEY = 'mySurveys/RECEIVE_DELETE_SURVEY';
