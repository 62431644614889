/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Breadcrumb, Upload, message } from 'antd';
import classNames from 'classnames';
import {
  DoubleLeftOutlined,
  DownloadOutlined,
  PictureOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { sectionSelector } from '../../selectors';
import {
  Upload as UploadIcon,
  Photo,
  Camera as CameraIcon,
} from '../../../../components/icons';
import CameraModal from '../cameraModal';
import { uploadFileToSurvey, fetchMediaLibrary } from '../../actions';
import { DOCUMENT_TYPES, IMAGE_TYPES } from '../../../../shared/constant';
import './style.scss';

const SurveyHeader = ({
  section,
  match,
  fixed,
  title,
  isSectionLoading,
  isFileUploading,
  uploadFileToSurvey,
  fetchMediaLibrary,
  pagination,
}) => {
  const [visible, setVisible] = useState(false);
  const { surveyId, sectionId } = useParams();
  const mediaLibrary = match.url.split('/')[3] === 'mediaLibrary';

  const beforeUpload = (file) => {
    const isAcceptedFiletypes =
      IMAGE_TYPES.indexOf(file.type) > -1 ||
      DOCUMENT_TYPES.indexOf(file.type) > -1;
    if (!isAcceptedFiletypes) {
      message.error('The file type is not accepted.');
    }
    const TEN_M = 10 * 1024 * 1024;
    const isLt10M = file.size < TEN_M;
    if (!isLt10M) {
      message.error('Maximum upload file size: 10 MB.');
    }
    return isAcceptedFiletypes && isLt10M;
  };

  const uploadMedia = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await uploadFileToSurvey(surveyId, formData);
      fetchMediaLibrary(surveyId, pagination);
      onSuccess(null, file);
      message.success(res);
    } catch (err) {
      onError();
      message.error(err.message);
    }
  };

  return (
    <div className={classNames('survey-header-container', { fixed })}>
      <div className="survey-header">
        <h1 className="banner">{title || 'Survey'}</h1>
        <div className="function">
          <div className="breadcrumb-normal">
            <Breadcrumb>
              {section && sectionId ? (
                <>
                  <Breadcrumb.Item
                    key="all-sections"
                    className="breadcrumb-normal-item"
                  >
                    <DoubleLeftOutlined
                      style={{ color: '#f1c400', marginRight: '8px' }}
                    />
                    <Link
                      to={`/surveys/${surveyId}`}
                      style={{ color: '#f1c400' }}
                    >
                      ALL SECTIONS
                    </Link>
                  </Breadcrumb.Item>
                  {section.title && !isSectionLoading && (
                    <Breadcrumb.Item
                      key={match.url}
                      className="breadcrumb-normal-item"
                    >
                      <Link to={match.url}>{section.title}</Link>
                    </Breadcrumb.Item>
                  )}
                </>
              ) : mediaLibrary ? (
                <>
                  <Breadcrumb.Item
                    key="all-sections"
                    className="breadcrumb-normal-item"
                  >
                    <DoubleLeftOutlined
                      style={{ color: '#f1c400', marginRight: '8px' }}
                    />
                    <Link
                      to={`/surveys/${surveyId}`}
                      style={{ color: '#f1c400' }}
                    >
                      ALL SECTIONS
                    </Link>
                  </Breadcrumb.Item>

                  <Breadcrumb.Item
                    key={match.url}
                    className="breadcrumb-normal-item"
                  >
                    <Link to={match.url}>MEDIA LIBRARY</Link>
                  </Breadcrumb.Item>
                </>
              ) : null}
            </Breadcrumb>
          </div>
          <div className="breadcrumb-small">
            <Breadcrumb>
              {section ? (
                <>
                  <Breadcrumb.Item
                    key="all-sections"
                    className="breadcrumb-small-item"
                  >
                    <DoubleLeftOutlined
                      style={{ color: '#f1c400', marginRight: '8px' }}
                    />
                    <Link
                      to={`/surveys/${surveyId}`}
                      style={{ color: '#f1c400' }}
                    >
                      ALL SECTIONS
                    </Link>
                  </Breadcrumb.Item>
                </>
              ) : null}
            </Breadcrumb>
          </div>
          <div className="btn">
            <Button
              type="link"
              icon={<DownloadOutlined />}
              className="btn-item"
              onClick={() => {
                window.open(`/surveys/${surveyId}/preview`, '_blank');
              }}
            >
              <span className="btn-name">DOWNLOAD</span>
            </Button>
            <Link to={`/surveys/${surveyId}/mediaLibrary`} className="btn-item">
              <Button type="link" icon={<PictureOutlined />}>
                <span className="btn-name">MEDIA LIBRARY</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <CameraModal
        visible={visible}
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
      />
      <div className="supporter" />
      <div className="survey-links">
        <Button
          type="link"
          className="camera-modal-btn sc-btn-secondary grad-top"
          icon={<CameraIcon />}
          onClick={() => {
            setVisible(true);
          }}
        />

        <Upload
          className="sc-btn-secondary grad-top"
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={uploadMedia}
          multiple
        >
          {isFileUploading ? <LoadingOutlined /> : <UploadIcon />}
        </Upload>

        <Link to={`/surveys/${surveyId}/mediaLibrary`}>
          <Button
            type="primary"
            icon={<Photo />}
            className="sc-btn-secondary grad-top"
          />
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  title: state.survey.title,
  section: sectionSelector(state),
  isSectionLoading: state.survey.isSectionLoading,
  isFileUploading: state.survey.isFileUploading,
  pagination: state.survey.pagination,
});

const mapDispatchToProps = { uploadFileToSurvey, fetchMediaLibrary };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SurveyHeader),
);
