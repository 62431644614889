import React from 'react';
import { Form, Button } from 'antd';
import './style.scss';

export default ({ loading }) => (
  <div className="form-btn">
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        className="sc-btn-primary"
        loading={loading}
        disabled={loading}
      >
        SAVE
      </Button>
    </Form.Item>
  </div>
);
