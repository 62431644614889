import React from 'react';
import logo from '../../assets/logo_vertical.png';
import './style.scss';

const BackCover = () => (
  <section className="report-preview-back-cover">
    <ul className="company-info-container">
      <li>
        <img src={logo} alt="logo" />
      </li>
      <li>
        <h1>LMI GROUP</h1>
        <div>
          LMI Group House, 428 Burke Road, Camberwell Vic 3124 PO Box 2116,
          Camberwell Vic 3124
        </div>
      </li>
      <li>
        <div className="contact">
          <h2>Phone:</h2>
          +61 3 9835 9900
        </div>
        <div className="contact">
          <h2>Email:</h2>
          <div>customerrelations@lmigroup.com</div>
        </div>
        <div className="contact">
          <h2>Website:</h2>
          <div>www.lmigroup.com</div>
        </div>
      </li>
    </ul>
  </section>
);

export default BackCover;
