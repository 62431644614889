import React from 'react';
import { connect } from 'react-redux';
import Title from '../title';
import TableBulk from './components/tableBulk';
import { reportSectionsSelector } from '../../../survey/selectors';
import './style.scss';

const ReportSections = ({ sections }) => {
  return (
    <div className="report-preview-sections">
      {sections.map((section) => {
        return section.title === 'Photographic Schedule' ? null : (
          <div className="section" key={section.id}>
            <Title title={section.title} />
            <div className="table-body">
              <TableBulk data={section} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sections: reportSectionsSelector(state),
});

export default connect(mapStateToProps, null)(ReportSections);
