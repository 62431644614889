import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSortAlphaUpAlt,
  faSortAlphaDownAlt,
  faChevronUp,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faFacebookSquare,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import App from './components/app';
import { store, persistor } from './store';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

import './index.scss';

library.add(
  faTwitter,
  faFacebookSquare,
  faYoutube,
  faSortAlphaUpAlt,
  faSortAlphaDownAlt,
  faChevronUp,
  faClock,
);

ReactDOM.render(
  <Provider store={store}>
    <App>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </App>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();
