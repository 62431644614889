import React from 'react';
import { Modal } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

export default ({
  children,
  visible,
  onOk,
  onCancel,
  width,
  style,
  ...props
}) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      keyboard={false}
      destroyOnClose
      onOk={onOk}
      onCancel={onCancel}
      {...props}
      width={width}
      style={{ position: 'relative', ...style }}
      closeIcon={
        <CloseCircleFilled
          style={{
            fontSize: '24px',
            color: '#ffc400',
            position: 'absolute',
            top: '-28px',
            right: '-28px',
          }}
        />
      }
    >
      {children}
    </Modal>
  );
};
