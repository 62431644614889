import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';
import { initialize as initializeAction } from './actions';

moment.locale('en-au');

const App = ({ children, initialize, initialized }) => {
  useEffect(() => {
    initialize();
  }, [initialize]);
  return initialized ? children : <Spin size="large" />;
};

const mapStateToProps = (state) => ({
  initialized: state.app.initialized,
});

const mapDispatchToProps = { initialize: initializeAction };

export default connect(mapStateToProps, mapDispatchToProps)(App);
